import React, { useEffect, useRef, useState } from "react";
import "./FooterStyle.css";
import { loadText, storeImage, storeText } from "../../api/ObjectStoreApi";
import "../GenericStyle.css";
import FileSelector from "../../fileSelector/FileSelector";
import HOST from "../../../configuration/backend/host";
import PROXY from "../../../configuration/proxy/proxy";
import { useNavigate } from "react-router-dom";

export default function Footer({tagPrefix, admin})
{

    let [fB, setFB] = useState("");
    let [fC, setFC] = useState("");

    let [title, setTitle] = useState("Descriere companie");
    let [banner, setBanner] = useState("no text");

    const titleRef = useRef(null);

    let [items, setItems] =useState({groups:[{items:[]},{items:[]}]});

    let [imageFile, setImageFile] = useState(null);

    let [updateSignal, setUpdateSignal] = useState(1);
    const redirect = useNavigate();
    useEffect(()=>{
        setItems({groups:[{items:[]},{items:[]}]});
        setTitle("Descriere companie");
        setBanner("no text");
        setFB("");
        setFC("");

        loadBackgroundColor();
        loadColor();
        loadTitle();
        loadBanner();
        loadItems();
    },
    [tagPrefix]);// shouldBe updateSignal but here only image uses it and the update on image is generated by link modification
    const storeBackgroundColor = ()=>{
        storeText(tagPrefix+".bC", fB);
    }
    const loadBackgroundColor = ()=>{
        loadText(tagPrefix+".bC").then((color)=>setFB(color)).catch((e)=>{});
    }
    const storeColor = ()=>{
        storeText(tagPrefix+".C", fC);
    }
    const loadColor = ()=>{
        loadText(tagPrefix+".C").then((color)=>setFC(color)).catch((e)=>{});
    }
    const storeTitle = (titleInput)=>{
        storeText(tagPrefix+".title", titleInput);
    }
    const loadTitle = ()=>{
        loadText(tagPrefix+".title").then((color)=>setTitle(color)).catch((e)=>{});
    }
    const storeBanner = (titleInput)=>{
        storeText(tagPrefix+".banner", titleInput);
    }
    const loadBanner = ()=>{
        loadText(tagPrefix+".banner").then((color)=>setBanner(color)).catch((e)=>{});
    }
    const storeItems = (itemsInput)=>{
        //recevive items from the JSX (thrre the value is up to date, here is not or you cat call the setter here to obtain the value, but is better like this)
        
        storeText(tagPrefix+".items", JSON.stringify(itemsInput));
    }
    const loadItems = ()=>{
        loadText(tagPrefix+".items").then((r)=>
            {
                try{
                    let i = JSON.parse(r);
                     if(!i || !i.groups)
                     {
                        i.groups = [{items:[]},{items:[]}];
                     }

                    setItems(i);
                    
                }catch(e)
                {console.log(e);}

            }
        ).catch((e)=>{});
    }

    const handleFileChange = (files)=>{
        
        setImageFile(files[0]);
    }
    const uploadInputImageFile = (file) =>{
        if(file)
        {
            storeImage(tagPrefix+".image", file).then((r)=>{setUpdateSignal(updateSignal+1)})
        }
    }
    const addObjectToListAndSetState = ( destinationObjectSetter,  keyChain, valueObject)=>
    {
        destinationObjectSetter((original)=>{
            try{
            
                let objectCopy = JSON.parse(JSON.stringify(original));
                let destination = objectCopy;
                let index = 0;
                while(index<keyChain.length  )
                {
                    
                    destination = destination[keyChain[index]];
                    index++;
                }
                if(!Array.isArray(destination))
                {
                    destination = [valueObject];
                }
                else{
                    destination.push(valueObject);
                }
                return objectCopy;

             
            }catch(e){
                return original;
            }
    });

    }
    const setObjectValueAndSetState = ( destinationObjectSetter,  keyChain, valueObject)=>
    {
        destinationObjectSetter((original)=>{
            try{
            
                let objectCopy = JSON.parse(JSON.stringify(original));
                let destination = objectCopy;
                let index = 0;
                while(index<keyChain.length -1 )
                {
                    destination = destination[keyChain[index]];
                    index++;
                }
               
                if(!destination)
                {
                    destination ={};
                }
                destination[keyChain[index]] = valueObject;
                return objectCopy;

             
            }catch(e){
                return original;
            }
        });
    }
    const getContent =()=>
    {
        if(admin)
        {
            return generateAdminContent();
        }
        else
        {
            return generateContent();
        }
    }
    const generateContent = ()=>{

        return (
            <div style = {{backgroundColor:fB}} className="footerContentMain fullParentWidth flex flexRow flexWrap flexJustifySpaceEvenly flexAlignContentCenter flexAlignItemsCenter" >
                {generateTitleContent()}
                {generateItemsContent()}
            </div>
        );
    }
    const generateAdminContent = ()=>{
        return (
            <div style = {{backgroundColor:fB}}  className="footerAdminContentMain" >
                <div className=" flex flexRow flexWrap flexJustifyStart" style={{padding:"1rem"}}>
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter breakWordPretty maxWidthParent" style={{padding:"0.5rem"}}>
                        <div className="breakWordPretty maxWidthParent">Culoare fundal</div>
                        <input value={fB?fB:"#ffffff"}   type="color" onInput={(event)=>{console.log(event.target.value); setFB(event.target.value);}}/>
                        <div className="check" onClick={storeBackgroundColor}/>
                    </div>

                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter breakWordPretty maxWidthParent" style={{padding:"0.5rem"}}>
                        <div className="breakWordPretty maxWidthParent">Culoare text</div>
                        <input value={fC?fC:"#ffffff"}   type="color" onInput={(event)=>{console.log(event.target.value); setFC(event.target.value);}}/>
                        <div className="check" onClick={storeColor}/>
                    </div>

                </div>
                <div className="fullParentWIdth flex flexRow flexWrap flexJustifySpaceEvenly flexAlignContentStart flexAlignItemsStart">
                    {generateTitleContent()}
                    {generateItemsContent()}
                </div>
            </div>
        );

    }

    const generateTitleContent =()=>
    {
        if(admin)
        {
            return (
            <div style={{maxWidth:"100%"}}>
                  <div className="imageLoad " style = {{boxShadow:"0 0 0.5rem -0.1rem black, 0 0 0.5rem -0.1rem white", borderRadius:"0.5rem"}}>
                        <div style={{width:"100%", height:"auto", aspectRatio:"1",
                        backgroundSize:"contain",
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"center",
                        backgroundImage:"url('"+HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/objectstore/image/"+tagPrefix+".image?imgKey="+updateSignal+"&width=750')"}}></div>
                        
                       <FileSelector uploadFiles={(files)=>{
                            console.log("files", files);
                            if(files && Array.isArray(files) && files.length>0){
                                handleFileChange(files);
                                uploadInputImageFile(files[0]);
                                }
                            }}/>
                        {/*<input type="file" id="image" accept="image/*" onChange={handleImageChange} style={{maxWidth:"100%", wordWrap:"break-word"}}/>*/}
                        {/*<div className="check" onClick={()=>uploadImageFile()}></div>*/}
                    </div>
                <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter" style = {{maxWidth:"100%"}}>

                    <div   className="contentEditableDiv breakWordPretty"   onBlur={(event)=>setTitle(event.target.innerText)} contentEditable>{title}</div>
                    <div onClick={()=>storeTitle(title)} className="check"/>
                </div>
            </div>
            );
        }
        else{
            return (
            <div className="flex flexColumn flexJustifyStart" style={{padding:"0.5rem"}} >
                 
                    <img style = {{width:"15rem"}} src = { HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/objectstore/image/"+tagPrefix+".image?imgKey="+updateSignal+"&width=750"}
                    alt = "logo">

                    </img>
               
                 <div className="title breakWordPretty" style={{fontSize:"3rem", fontWeight:"600", textAlign:"center"}}> {title} </div>

            </div>
           
            );
        }
    }
    const generateItemsContent = ()=>{
        let groups = [];
        let itemsCopy = JSON.parse(JSON.stringify(items));
        if(itemsCopy && itemsCopy.groups && Array.isArray(itemsCopy.groups))
        {

            for(let index in itemsCopy.groups)
            {
                let group = itemsCopy.groups[index];
                groups.push(generateItemsGroup(group, ["groups", index]));
            }
        }

        if(admin)
        {
               groups.push(
                <div>
                    <div className="breakWordPretty"> Salveaza linkuri si informatii</div>
                    <div className="check" onClick={()=>{
                  storeItems(items);}}></div>
                </div>
               );
        }
        return groups;
    }
    const generateItemsGroup = (group, keyChain)=>{
       
        let result = [];
            if(group && group.items && Array.isArray(group.items))
            {
                for(let index in group.items)
                {
                    let item = group.items[index];
                    result.push(generateItem(item,[...keyChain, "items", index]))
                }
            }
         
         if(admin)
         {
                result.push(
                
                <div className="flex flexRow flexWrap flexJustifyCenter" style = {{flexGrow:"1", maxWidth:"100%", padding:"0.5rem"}}> 
                    <div 
                    onClick={()=>{addObjectToListAndSetState(setItems, [...keyChain, "items"], {title:"Titlu ",subtitle:"Subtitlu", link:"Link"});}}
                    className="add">
                    </div>
                </div>);
         }

         return (
            <div className="flex flexNoWrap flexColumn" style={{maxWidth:"100%", padding:"1.5rem"}}>
                {result}
            </div>
         );

    }
    const generateItem = (item, keyChain)=>{
        if(!item)
        {
            return;
          // setObjectValueAndSetState(setItems, keyChain, {text:"text",link:"link" })
        }
        else{
            if(!item.title)
            {
                setObjectValueAndSetState(setItems, [...keyChain, "title"], "title");
            }
            if(!item.subtitle)
            {
                setObjectValueAndSetState(setItems, [...keyChain, "subtitle"], "subtitle");
            }
            if(!item.link)
            {
                setObjectValueAndSetState(setItems, [...keyChain, "link"], "link");
            }
        }
        if(admin)
        {
            return (
                <div className="footerGroupItemAdminContainer flex flexColumn flexJustifyStart flexAlignContentEnd flexAlignItemsEnd">
                    <div className="close" onClick={()=>{setObjectValueAndSetState(setItems, keyChain, undefined )}}> </div>

                    <div className="contentEditableDiv fullParentWidth" contentEditable onBlur = {(event)=>
                        {console.log(event.target.innerText);
                            setObjectValueAndSetState(setItems, [...keyChain, "title"], event.target.innerText);
                        }}>{item.title}</div>
                    <div className="contentEditableDiv fullParentWidth" contentEditable onBlur = {(event)=>
                        {console.log(event.target.innerText);
                            setObjectValueAndSetState(setItems, [...keyChain, "subtitle"], event.target.innerText);
                        }}>{item.subtitle}</div>
                    <div  className="contentEditableDiv fullParentWidth" contentEditable onBlur = {(event)=>
                        {console.log(event.target.innerText);
                            setObjectValueAndSetState(setItems, [...keyChain, "link"], event.target.innerText);
                        }}>{item.link}</div>


                </div>
            );

        }else{
            return(
                <div className=" flex flexColumn flexJustifyStart flexAlignItemsStart flexAligncontentStart"onClick = {()=>{if(item.link && item.link.length>1){window.location.href = item.link}}} style={{padding:"0 0 1rem 0", cursor:"pointer"}}>
                   {(item.title && item.title.length>1) &&  <div className="breakWordPretty" style={{fontSize:"1.2rem", fontWeight:"700", textAlign:"center"}}> 
                        {item.title}
                    </div>}
                    {(item.subtitle && item.subtitle.length>1)&& <div className="breakWordPretty" style={{fontSize:"1.2rem", fontWeight:"300", padding:"0 0 0 0", textAlign:"center"}}>
                        {item.subtitle}
                    </div>}
                </div>
            );
        }
    }
    return(
        <div style = {{color:fC, backgroundColor:fB}} className="footerMain">
            {getContent()}
            {admin && 
                <div className="banner">
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter" style = {{maxWidth:"100%"}}>
                        <div   className="contentEditableDiv breakWordPretty"   onBlur={(event)=>setBanner(event.target.innerText)} contentEditable>{banner}</div>
                        <div onClick={()=>storeBanner(banner)} className="check"/>
                    </div>
                </div>
            }
            {!admin &&
                <div className="breakWordPretty banner fullParentWidth  " style={{padding:"1rem", borderTop: "0.5px solid "+fC, textAlign:"center", lineHeight:"1.5rem"}}>
                    {banner}
                </div>
            }
        </div>
    );
}