import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PasiveDecorator from "../animation/decorator/pasiveDecorator";
import Login from "../login/login";
import GoogleAuthCallback from "../googleAuth/googleAuthCallback";
import Admin from "../admin/admin";
import "./main.css"
import AnimatedCircles from "./BackgroundAnimation";
import ProductDashboard from "../client/product/ProductDasboard";
import ProductPage from "../client/product/ProductPage";
import ProductExplore from "../client/product/ProductExplore";
import ProductRouter from "../client/product/ProductRouter";
import ProductTopbar from "../client/product/ProductTopbar";
import Home from "../home/Home";
import SwipeContainer from "../rdHome/SwipeContainer";
import Test from "../rdHome/Test";
import GenericComponentsTest from "../genericComponents/test/GenericComponentsTest";
import ColumnComponentContainer from "../genericComponents/componentContainer/ColumnComponentContainer";
  const Main = (props) => {


    const entryPage = useRef(null);
    let [isLoaded, setLoaded] = useState(false);
    /*
    useEffect(()=>{
        if(entryPage.current){
        //let decoration = PasiveDecorator.generateDecorationDOMElement("entryPage");
        //console.log(entryPage.current, Object.getPrototypeOf(document.getElementById("testid")));
       
       // entryPage.current.appendChild(decoration);
          return ()=>{
            if(entryPage.current){
           // entryPage.current.removeChild(decoration);
        }
         }
    }
        
    },[]);
    */
    useEffect(()=>{
         let loadedHandler = ()=>{alert("sdf");setLoaded(true);}
  
       //window.onload = loadedHandler;
       //window.onclick = loadedHandler;
      // window.onmousedown = loadedHandler();
        return ()=>{
          
        //window.removeEventListener("load", loadedHandler);
        
        }
        
    },[])
     return (
    <BrowserRouter >
 
          
       {false &&  <div className="mainBackgroundContainer"><AnimatedCircles/></div>}
        {false && <div style={{width:"3rem", height:"3rem", background:"red"}}>sdsd</div>}
             
                    <Routes >
                        <Route path = {"/*"} element = {  
                        
                        <div className="mainMain">
                            {/*<ProductTopbar/>*/}
                            <Routes>
                                <Route path = {"product/*"} element = {<ProductRouter/>}></Route>
                                <Route path = {"profile"} element = {<><ProductTopbar/><Login/></>}></Route>
                                <Route path = {"auth/google/callback"} element = {<GoogleAuthCallback/>}></Route>
                                <Route path = {"admin/*"} element = {<><ProductTopbar/><Admin/></>}></Route>
                            </Routes>
                        </div>
                            
                            
                            
                            }>
                            
                        </Route>
                        <Route path = {"/"} element = { <ColumnComponentContainer tagPrefix={"home1"}/>}>
                            
                        </Route>
                        <Route path = {"/rd"} element = { <Test/>}>
                            
                        </Route>
                        <Route path = {"/gct"} element = { <GenericComponentsTest/>}>
                            
                        </Route>
                        <Route path = {"/gct/admin"} element = { <GenericComponentsTest admin={true}/>}>
                        
                        </Route>
                         
                    </Routes>
                    <div  style={{zIndex : "10"}}ref={entryPage}></div>

                </BrowserRouter>
                );

         
}
export default Main;