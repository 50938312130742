import React, { useEffect, useRef, useState } from "react";
import "./TopbarStyle.css";
import UserProvider from "../../../userProvider/UserProvider";
import { useNavigate } from "react-router-dom";
import FileSelector from "../../fileSelector/FileSelector";
import HOST from "../../../configuration/backend/host";
import PROXY from "../../../configuration/proxy/proxy";
import { loadText, storeImage, storeText } from "../../api/ObjectStoreApi";


export default function Topbar({tagPrefix, admin}){

    let minWrapLevel = 1;
    let maxWrapLevel= 10;
    let [userDetails, setuserDetails] = useState(null);
    let redirectTo = useNavigate();
    let topBar = useRef(null);
    let [wrapLevel, setWrapLevel] = useState(1);

    let [displayDropdown, setDisplayDropdown] = useState(false);


    let [imageFile, setImageFile] = useState(null);
    let [updateSignal, setUpdateSignal] = useState(1);
    let [bC, setBC] = useState("");
    let [wC, setWC] = useState("");
    useEffect(()=>{

        let observer = (newUserDetails)=>{
            setuserDetails(newUserDetails);
        }
        setuserDetails(UserProvider.userDetails);
        UserProvider.refresh().then(()=>{setuserDetails(UserProvider.userDetails)});
        UserProvider.observers.push(observer);


        let resizeHandler = ()=>{
            setWrapLevel(-1);
            

        }
        let rsObs = new ResizeObserver(resizeHandler);
        if(topBar.current)
        {
            rsObs.observe(topBar.current);
        }
        return ()=>{
            UserProvider.observers = UserProvider.observers.filter((el)=>!(el===observer));
            if(topBar.current)
            {
                rsObs.unobserve(topBar.current);
            }
        }
    },[]);
    useEffect(()=>{
        console.log("tr", wrapLevel);
       ajustWrapLevel(wrapLevel);
    },[wrapLevel]);

    useEffect(()=>{
    setWC("");
    setBC("");
    loadWColor();
    loadBColor();
         },[tagPrefix, admin, updateSignal]);

    const ajustWrapLevel = (wL)=>{
        console.log(isOverflow());
        console.log('trigered', wL, isOverflow());
        if(isOverflow())
        {
            if(wL<=maxWrapLevel)
            {
                setWrapLevel(wL+1);
            }
        }else{
            if(wL<0)
            {
                setWrapLevel(minWrapLevel);
            }
            //at ersize reset wraplevel witch will retriger this
        }
    }


    const isOverflow = ()=>{
    
        if(topBar.current)
        {
            return topBar.current.clientWidth<topBar.current.scrollWidth || topBar.current.clientHeight<topBar.current.scrollHeight;
        }

    }
    const generateUserProfile  = (fullName)=>{

        if(userDetails && userDetails.googleData)
        {
            let gD = JSON.parse(userDetails.googleData);
            if(gD.picture)
            {
                if(fullName)
                {
                    return [<div className="user" style = {{backgroundImage:'url('+gD.picture+')'}}/>, 
                   (wrapLevel<2 || fullName) && <div className="profileInfo"> {(gD&& gD.given_name)?(gD.given_name+((gD.family_name)?" "+gD.family_name:"")):"Profil"}</div>]
       
                }
                return [<div className="user" style = {{backgroundImage:'url('+gD.picture+')'}}/>, 
                (wrapLevel<2 || fullName ) && <div className="profileInfo"> {(gD&& gD.given_name)?(gD.given_name.charAt(0)+"."+((gD.family_name)?gD.family_name.charAt(0)+".":"")):"Profil"}</div>]
           
        }
        }
        return [<div className="user"/>, (wrapLevel<2 || fullName) && <div className="profileInfo">Profil</div>]
    }

    const handleFileChange = (files)=>{
        
        setImageFile(files[0]);
    }
    const uploadInputImageFile = (file) =>{
        if(file)
        {
            storeImage(tagPrefix+".image", file).then((r)=>{setUpdateSignal(updateSignal+1)})
        }
    }
    const storeWColor = ()=>{
        storeText(tagPrefix+".wC", wC);
    }
    const loadWColor = ()=>{
        loadText(tagPrefix+".wC").then((color)=>setWC(color)).catch((e)=>{});
    }
    const storeBColor = ()=>{
        storeText(tagPrefix+".bC", bC);
    }
    const loadBColor = ()=>{
        loadText(tagPrefix+".bC").then((color)=>setBC(color)).catch((e)=>{});
    }
    return(
        <div className="topbarMainSecondWrapper" style = {{backgroundColor:(admin?"":wC)}}>
            {admin && <div className="admin">
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter breakWordPretty maxWidthParent" style={{padding:"0.5rem"}}>
                        <div className="breakWordPretty maxWidthParent">Culoare margini</div>
                        <input value={wC?wC:"#ffffff"}   type="color" onInput={(event)=>{console.log(event.target.value); setWC(event.target.value);}}/>
                        <div className="check" onClick={storeWColor}/>
                    </div>
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter breakWordPretty maxWidthParent" style={{padding:"0.5rem"}}>
                        <div className="breakWordPretty maxWidthParent">Culoare topbar</div>
                        <input value={bC?bC:"#ffffff"}   type="color" onInput={(event)=>{console.log(event.target.value); setBC(event.target.value);}}/>
                        <div className="check" onClick={storeBColor}/>
                    </div>
            <div className="imageLoad">
                    <div className="flex flexColumn flexJustifyStart flexAlignItemsCenter flexAlignContentCenter" style={{width:"100%", height:"fit-content"}}>
                        <img src = {HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/objectstore/image/"+tagPrefix+".image?imgKey="+updateSignal+"&width="+("250")}></img>
                    </div>
                        
                       <FileSelector uploadFiles={(files)=>{
                            console.log("files", files);
                            if(files && Array.isArray(files) && files.length>0){
                                handleFileChange(files);
                                uploadInputImageFile(files[0]);
                                }
                            }}/>
                        {/*<input type="file" id="image" accept="image/*" onChange={handleImageChange} style={{maxWidth:"100%", wordWrap:"break-word"}}/>*/}
                        {/*<div className="check" onClick={()=>uploadImageFile()}></div>*/}
                    </div>    
            </div>}

          {!admin&&  <div ref={topBar} className="topbarMainWrapper">
                <div className="topbarMain flex flexRow flexNoWrap flexJustifySpaceBetween" style = {{backgroundColor:bC}}>
                    <div style = {{padding:"0.1rem 0.5rem 0.1rem 0.5rem"}}className="topbarLogoWrapper  flex flexRow flexNoWrap flexJustifyCenter flexAlignItemsCenter flexAlignContentCenter">
                        
                        {wrapLevel <10 &&   <img onClick={()=>{redirectTo("/")}} onLoad = {()=>{setWrapLevel(-1)}}style = {{maxHeight:"2.5rem", cursor:"pointer"}} src = {HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/objectstore/image/"+tagPrefix+".image?imgKey="+updateSignal+"&width="+("250")}>
                        </img>}
                    </div>
                
                    
                    <div className="topbarActions flex flexRow flexNoWrap flexJustifySpaceEveny flexAlignContentCenter flexAlignItemsCenter">
                        
                        {wrapLevel<9 && <div className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/product")}>
                            <div className="product" onClick={()=>{redirectTo("/product")}}/>
                            {wrapLevel<5&&<div>Produse</div>}
                        </div>}

                        {wrapLevel<8 && <div className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/")}>
                            <div className="home" onClick={()=>{redirectTo("/")}}/>
                            {wrapLevel<4&&<div>Acasa</div>}
                        </div>}
                        

                        {false && wrapLevel<7 && <div style={{display:"none"}}className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/wishlist")}>
                            <div className="favourites"/>
                            {wrapLevel<3&&<div> Favorite</div>}
                        </div>}

                        
                        {wrapLevel<6 && <div className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/profile")}>
                            {generateUserProfile()}
                        </div>}

                        <div onClick = {()=>setDisplayDropdown(!displayDropdown)} className="meniu"/>

                        
                    </div>
                

                </div>
            
            </div>}
            {!admin && <div className={"sidebar " + (displayDropdown?"visible":"hidden") }>
                            <div className=" closeWrapper fullParentWidth flex flexRow flexNoWrap flexJustifyEnd">
                                <div className="close coverBackground" onClick={()=>setDisplayDropdown(false)}/>
                            </div>
                            <div className="body">
                            <div onClick = {()=>{redirectTo("/product"); setDisplayDropdown(false);}}className="item flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlingContentEnd" >
                                    <div> Produse </div>
                                    <div className="product"/>

                                </div>
                                <div onClick = {()=>{redirectTo("/");setDisplayDropdown(false);}}className="item flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlingContentEnd" >
                                    <div> Acasa </div>
                                    <div className="home"/>

                                </div>
                                {false && <div onClick = {()=>{redirectTo("/wishlist");setDisplayDropdown(false);}}className="item  flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlignItemsEnd" >
                                    <div> Favorite </div>
                                    <div className="favourites"/>

                                </div>}

                                 <div onClick = {()=>{redirectTo("/profile");setDisplayDropdown(false);}}className="item flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlignItemsEnd" >
                                    {generateUserProfile(true)&& Array.isArray(generateUserProfile(true)) && generateUserProfile(true).reverse()}

                                </div>
                            </div>
            </div>}
        </div>
    );
}