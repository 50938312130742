import React from "react";
import SwipeContainer from "./SwipeContainer";
import ScrollableElement from "./ScrollableElement";
import ObjectPresenter from "./ObjectPresenter";
export default function Test(){
    
    return(
        <SwipeContainer elementsInput={[<ObjectPresenter/>,<ObjectPresenter inputElements={[{image:"https://assets.vogue.com/photos/61bb738170820442686f1c84/1:1/w_2304,h_2304,c_limit/VO1121_FashionFund_01.jpg",
            background:"#848484", title:"pwkjbenfkjsdnfkjsndfsdfsdfsdfsdfsdfsd",subtitle :"f"},{image:"https://img.freepik.com/free-photo/abstract-scene-background-luxury-podium-background-gold-product-presentation-mock-up_272375-4895.jpg",
            background:"red", title:"Produs AB", subtitle:"svsdv"},{image:"https://i.pinimg.com/736x/41/cb/51/41cb517a3e82fbfda91e9bb68bfb67a4.jpg",
            background:"green", title:"Engie", subtitle:"ceva pula de cal inmu iata in lapte"}]}/>, <ObjectPresenter/>,<ScrollableElement/>, <ScrollableElement/>, <ScrollableElement/>,<ObjectPresenter/>]}></SwipeContainer>
    );
}