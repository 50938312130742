import React, { useState } from "react";
import "./AddCategoryFormStyle.css";
import { performHttpRequest } from "../../api/http/generic";

export default function AddAdminForm({title, parentId, closeFunction})
{


    let [name, setName] = useState("");

    const addNewCategory = ()=>{
        performHttpRequest("POST", "/user/admin/setAdmin", null, JSON.stringify({email:name}),(r,c,e,j)=>{
                if(c>=200 && c<=206)
                {
                    //alert("SUCCESS");
                    closeFunction();
                }
        });
    }
    return (
        <div className="addCategoryFormMain flex flexColumn flexJustifySpaceBetween flexAlignContentCenter flexAlignItemsCenter">
            <div className="addCategoryFromHeader boldText ">{title?title:""}</div>
            <div className="addCategoryFormBody flex flexColumn flexJustifyStart">

                <div className="">E-mail:</div>
                <input  onInput = {(event)=>{setName(event.target.value)}} value={name} type="text"/>
                
                 

            </div>
            <div className="addCategoryFormFooter flex flexRow flexWrap flexJustifySpaceEvenly">
                <button onClick={()=>addNewCategory()}>Adauga</button>
                <button onClick={()=>closeFunction()}>Renunta</button>

            </div>
        </div>
    );
}