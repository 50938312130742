import React, { useEffect, useState } from "react";
import "./loginStyle.css"
import { performHttpRequest } from "../api/http/generic";
import UserProvider from "../userProvider/UserProvider";
import { generateGoogleOauthLink } from "../configuration/oauth/google";
import { useNavigate } from "react-router-dom";


export default function Login(props){

    let [userDetails, setUserDetails] = useState(null);

    let redirectTo = useNavigate();
    useEffect(()=>{
        setUserDetails(UserProvider.userDetails);
        UserProvider.refresh().then(()=>{setUserDetails({original:UserProvider.userDetails, googleData:(UserProvider.userDetails?.googleData)?JSON.parse(UserProvider.userDetails?.googleData):null})});
    },[]);

    const logOut = ()=>{
        performHttpRequest("GET","/user/auth/logout",null,null,(r,c,e,j)=>{
            UserProvider.refresh().then(()=>{setUserDetails({original:UserProvider.userDetails, googleData:(UserProvider.userDetails?.googleData)?JSON.parse(UserProvider.userDetails?.googleData):null})});

        });
    }

    const redirectToGoogleLogin = ()=>{
        window.open(generateGoogleOauthLink()            , "_current");
    }
    const isAuthenticated = ()=>{
        return userDetails && userDetails.original && userDetails.original.id;
    }
    const isAdmin = ()=>{
        return userDetails && userDetails.original && userDetails.original.admin;
    }
    return(
        <div className="loginMain flex flexColumn flexNoWrap flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter">
           <div className="wrapper flex flexColumn flexNoWrap flexJustifySpaceEvenly flexAlignContentCenter flexAlignItemsCenter">
            { isAuthenticated() && <div className="userCard flex flexRow flexWrap flexJustifyStart flexAlignItemsCenter flexAlignContentCenter">
                    <div className="left">
                        <div className="avatar" style = {{backgroundImage:"url("+userDetails?.googleData?.picture+")"}}/>
                    </div>
                    <div className="right flex flexColumn ">
                        <div className="name">
                            {userDetails?.googleData?.given_name + " " + userDetails?.googleData?.family_name}
                        </div>
                        <div className="email">
                        {(userDetails?.original?.email && userDetails?.googleData?.email && (userDetails?.original?.email === userDetails?.googleData?.email))?(userDetails?.original?.email):(userDetails?.original?.email +" "+ userDetails?.googleData?.email)}

                        </div>
                        <div className="role">
                        {userDetails?.original?.admin?"ADMIN":"CLIENT"}
                        </div>
                    </div>
                </div>}
                <div className="loginCard flex flexColumn flexJustifyStart ">
                    <button onClick={()=>{redirectToGoogleLogin()}}>Log In with Google </button>
                    {isAuthenticated() &&  <button onClick = {logOut}>LogOut </button>}
                    {isAdmin() &&  <button onClick = {()=>redirectTo("/admin")}>Administrare site </button>}

                </div>
            </div>
        </div>
    );
}