import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { performHttpRequest } from "../api/http/generic";
import { generateRedirectUri } from "../configuration/oauth/google";

export default function GoogleAuthCallback(){
    let [queryParams, setParams] = useSearchParams();
    let [authCode, setAuthCode]= useState(null);
    let redirectTo = useNavigate();
    useEffect(()=>{

             
    let iterator = queryParams.entries();
    let obj = iterator.next();
    while(!obj.done)           
    {
        console.log(obj.value);
        obj = iterator.next();

    }
    let authC = queryParams.get("code");
    setAuthCode(authC);
    },[]);
    useEffect(()=>{
        if(authCode)
        {
            performHttpRequest("POST", "/user/auth/google", null, JSON.stringify({authCode:authCode, redirectUri: generateRedirectUri()}),(response, code, error, json)=>{
                redirectTo("/profile");
                
            })
        }
    },[authCode]);

    return (<>Sending Google authentication data to Loresshop servers
    {authCode && <div> {"authCode: "+authCode+" \n "}</div>}
    </>);
}