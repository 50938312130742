import React from "react";
import ComponentsMetaDataset from "../componentDataset/componentsMeta";
import "./ComponentSelectorStyle.css";


export default function ComponentSelector({selectionHandler}){

    const generateComponentListContent = ()=>{
        let result = [];

        let metadataForContentCreation = [];
        for(let key in ComponentsMetaDataset)
        {
            metadataForContentCreation.push({componentKey:key , componentObject: ComponentsMetaDataset[key]});
        }
        metadataForContentCreation.sort((a,b)=>{return a.componentObject.orderIndex-b.componentObject.orderIndex});
        for(let index in metadataForContentCreation)
        {
            let component = metadataForContentCreation[index].componentObject;
            result.push(
                <div className="componentMetaContainer flex flexRow flexWrap flexJustifySpaceBetween flexAlignContentCenter flexAlignItemsStretch ">
                    <div className="flex flexRow flexWrap flexJustifySpaceBetween flexAlignContentCenter flexAlignItemsCenter" style={{maxWidth:"100%"}}>
                        <div className="image" style = {{ backgroundImage:"url('"+component.image+"')"}}>

                        </div>
                        <div className=" text flex flexColumn flexJustifyStart flexAlignContentStart flexAlignItemsStart" style={{padding:"0.5rem"}}>
                            <div className="title">
                                {component.title}
                            </div>
                            <div className="description">
                                {component.description}
                            </div>
                        </div>
                    </div>
                    <div style = {{flexGrow:"1"}} className="flex flexRow flexWrap flexJustifyEnd flexAlignContentEnd flexAlignItemsEnd">
                        <button onClick = {()=>selectionHandler?selectionHandler(metadataForContentCreation[index].componentKey):null} style = {{whiteSpace:"nowrap"}}>Selecteaza</button>
                    </div>

                </div>
            );
        }
        return (
            <div className = "componentListContainer flex flexColumn flexJustifyStart flexAlignItemsStretch flexAlignContentStretch">{result}</div>
            );
    }

    return (
        <div className="componentSelectorMain flex flexColumn flexJustifyStart flexAlignItemsCenter flexAlignContentCenter">
            {generateComponentListContent()}
        </div>
    );
}