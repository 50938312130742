import React, { useState } from "react";
import AdminProduct from "./AdminProduct"
import CategoryTree from  "./CategoryTree"
import "./../category/CategoryAdminStyle.css";
import { useNavigate } from "react-router-dom";

export default function ProductAdmin(){

    let [signal, setSignal] = useState(false);
    let [showFixedSidebar, setShowFixedSidebar]= useState(false);
    let [selectedCategory, setSelectedCategory] = useState(null);
    let redirect = useNavigate();
    return(
        <div className="categoryAdminMainWrapper">
            <div className="flex flexRow flexJustifySpaceBetween"> 
                <div className="locationInfo lightText">Administrare produse</div>
                <div   className = "categoryAdminFixedSidebarActivation" onClick= {()=>setShowFixedSidebar(!showFixedSidebar)}>
                <svg fill="#000000" className="categoryAdminFixedSidebarActivationIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M21,1H3A3,3,0,0,0,3,7H21a3,3,0,0,0,0-6Zm0,4H3A1,1,0,0,1,3,3H21a1,1,0,0,1,0,2Z"/><path class="cls-1" d="M21,17H3a3,3,0,0,0,0,6H21a3,3,0,0,0,0-6Zm0,4H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"/><path class="cls-1" d="M21,9H3a3,3,0,0,0,0,6H21a3,3,0,0,0,0-6Zm0,4H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"/></svg>                
                </div>

            </div>

            {showFixedSidebar && 
            <div className="categoryAdminFixedSidebar">
                <div onClick = {()=>setShowFixedSidebar(false)} className="categoryAdminFixedSidebarCloseButton">
                    {false &&   <svg  className="categoryAdminFixedSidebarCloseIcon" height="256.000000pt" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 256.000000 256.000000" width="256.000000pt" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#000000" stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)">
                        <path d="M256,33C132.3,33,32,133.3,32,257c0,123.7,100.3,224,224,224c123.7,0,224-100.3,224-224C480,133.3,379.7,33,256,33z    M364.3,332.5c1.5,1.5,2.3,3.5,2.3,5.6c0,2.1-0.8,4.2-2.3,5.6l-21.6,21.7c-1.6,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3L256,289.8   l-75.4,75.7c-1.5,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6c0-2.1,0.8-4.2,2.3-5.6l75.7-76   l-75.9-75c-3.1-3.1-3.1-8.2,0-11.3l21.6-21.7c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l75.7,74.7l75.7-74.7   c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l21.6,21.7c3.1,3.1,3.1,8.2,0,11.3l-75.9,75L364.3,332.5z"/>
                        </g>
                    </svg>
                    }

                    <svg className="categoryAdminFixedSidebarCloseIcon" height="512px" id="Layer_1"  version="1.1" viewBox="0 0 512 512" width="512px">
                        <g>
                            <path d="M256,32C132.3,32,32,132.3,32,256s100.3,224,224,224s224-100.3,224-224S379.7,32,256,32z M384,272H128v-32h256V272z"/>
                        </g>
                    </svg>



                </div>

                <CategoryTree selectionHandler={(selectedId, name)=>{setSelectedCategory({id:selectedId, name:name});setShowFixedSidebar(false);}} signal = {signal} parentId={null}/>
                <div onClick = {()=>{setSelectedCategory(null);setShowFixedSidebar(false);}}className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter">
                                <svg  className="adminSpecificCategoryAddCategoryIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                                 <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                                </svg>
                                <div className="lightText">fara categorie</div>
                </div>
            </div>
            }
                

            <div className="categoryAdminMain flex flexRow flexWrap flexJustifyStart ">
              
                    <div className="productAdminSidebarWrapper  flexColumn flexJustifyContentStart flexAlignItemsCenterStart flexAlignContentStart">
                        <div className="categoryAdminSidebar">
                            <CategoryTree selectionHandler={(selectedId,name)=>setSelectedCategory({id:selectedId, name:name})} signal = {signal} parentId={null}/>
                            
                        </div>
                        <div onClick = {()=>{setSelectedCategory(null)}}className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter">
                                <svg  className="adminSpecificCategoryAddCategoryIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                                 <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                                </svg>
                                <div className="lightText">fara categorie</div>
                        </div>
                    </div>
                
                <div className="categoryAdminBody">
                    <AdminProduct  categoryId = {selectedCategory?.id} categoryName = {selectedCategory?.name} notifyParent={()=>{setSelectedCategory(null);setSignal(!signal)}}/>
                </div>
            </div>
        </div>
    );
}