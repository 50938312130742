import React, { useEffect, useRef, useState } from "react";
import "./ProductDashboardStyle.css"
import { useParams } from "react-router-dom";
import { performHttpRequest } from "../../api/http/generic";
import HOST from "../../configuration/backend/host";
import PROXY from "../../configuration/proxy/proxy";
import ImageCarousel from "./ImageCarousel";

export default function ProductPage({hidden}){

    let params = useParams();
    let [product, setProduct] = useState(null);

    let [resetSignal, setResetSignal] = useState(false);
     let textarea = useRef(null);
 



    useEffect(()=>{
      
        setProduct(null);
        setResetSignal(!resetSignal);
         
        fetchProduct();

        window.scrollTo(0,0);
        console.log("chageParams", params);
        },[params]);
    useEffect(()=>{
        /*
        if(textarea.current && product)
        {
            textarea.current.textContent = product.description;
            textarea.current.style.heigh = "auto";
            console.log(textarea.current.offsetHeight, textarea.current.clientHeight, textarea.current.scrollHeight);
            let finalHeight =  textarea.current.scrollHeight;
            console.log(textarea.current.offsetHeight, textarea.current.clientHeight, textarea.current.scrollHeight, finalHeight);

             textarea.current.style.height = finalHeight+"px"; // Set the height based on content

        }
        */
    },[product]);
    const fetchProduct = ()=>{
        if(params && params.productId)
        {
            performHttpRequest("GET","/generic/product/"+params.productId+"?attributes=id,title,subtitle,description&references=image;id-index",null,null,(r,c,e,j)=>{
                if(c>=200 && c<=206)
                {
                    if(r)
                    {
                        let finalProduct ={
                            id: r.id,
                            title: r.title,
                            subtitle: r.subtitle,
                            description: r.description,
                            images:[]
                        }
                        if (r.Images && Array.isArray(r.Images))
                        {
                            for(let index in r.Images)
                            {
                                if(r.Images[index])
                                {
                                    finalProduct.images.push({id:r.Images[index].id , index: r.Images[index].ProductImage?.index});
                                     
                                }
                            }
                        }
                        finalProduct.images.sort((a,b)=>a.index-b.index);
                        setProduct(finalProduct);
                        console.log(finalProduct);
                    }
                }
            });
        }
    }
    const getImageList = ()=>{
        let result = [];
        if(product && product.images && Array.isArray(product.images) && product.images.length >0)
        {
            for(let index in product.images)
            {
                result.push(product.images[index].id);
            }
        }
        return result;
    }

 
    
    return (
        <div className={"productPageMainWrapper "+(hidden?"hidden":"")}>
            <div className="productPageMain flex flexRow flexWrap flexJustifySpaceBetween   ">
                <div className="imageArea">
                <ImageCarousel resetSignal = {resetSignal} imageIdList = {getImageList()}/>
                            {/* <div className="imagePreview">
                                move it in the carousel section
                                </div>

                                <div className="imageView">
                  
                            
                                    <div onClick = {()=>previousImage()} className="carouselAction previous">{"<"}</div>
                                    <div onClick = {()=>nextImage()} className="carouselAction next">{">"}</div>
                                    <div  ref={carousel} className="imageCarousel flex flexRow flexNoWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter"> 
                                    {generateImageView()}
                                    </div>
                                
                               </div>
                            */}
                </div>
                <div className="titleArea flex flexColumn flexJustifyCenter flexAlignItemsStart flexAlignContentStart">
                    <div className="title">
                        {product?.title}
                    </div>
                    <div className="subtitle">
                        {product?.subtitle}
                    </div>
                </div>
                {false && <textarea ref = {textarea} value = {product?.description } readOnly className="descriptionArea"/>}
                <div   value = {product?.description } readOnly className="descriptionArea">
                {product?.description }
                </div>

                {false && JSON.stringify(product)}
            </div>
        </div>
    );
}