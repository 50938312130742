import React, { useRef } from "react";
import "./ModalStyle.css"

export default function Modal({element, isOpen, closeFunction}){

    let modal = useRef(null);

    return (
        <div ref = {modal} className={("genericModalMain")+(isOpen?" open":"")}>
            <div className ="close" onClick={()=>closeFunction()}>
                <svg  className="genericModalCloseIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="5%" y1="5%" x2="90%" y2="90%"></line>
                    <line x1="5%" y1="90%" x2="90%" y2="5%"></line>
                </svg>
            </div>
            <div className="genericModalContent">
                {isOpen && element}
            </div>
        </div>
    );
}