import React, { useEffect, useState } from "react";
import GenericItemCarousel from "../../itemContainer/layout/carousel/GenericItemCarousel";
import "../../itemContainer/ContainerBootstrapStyle.css";
import P1T1S1 from "../item/P1T1S1";
import { clearAllByPrefix, loadText, storeText } from "../../api/ObjectStoreApi";
import "../GenericStyle.css"
import P1T1S1FullViewport from "../item/P1T1S1FullViewport";
export default function FullScreenItemCarousel({tagPrefix, admin}){

    let [itemsMeta, setItemsMeta] = useState({items:[], nextId:1});
    

    /*
    {itemId:...,
     itemOrderIndex:...}
        */


    useEffect(()=>{
        setItemsMeta({items:[], nextId:1});
      
        fetchItemsMeta();
      
    },[tagPrefix, admin]);

    const fetchItemsMeta = ()=>{

        loadText(tagPrefix+".itemsMeta").then((r)=>{
            try{
                let meta = JSON.parse(r);
                if(!meta?.items || !Array.isArray(meta.items))
                {
                    meta.items =[];
                }
                if(!meta.nextId)
                {
                    meta.nextId = meta.items.length+1;
                }
                setItemsMeta(meta);
            }
            catch(e)
            {

            }
        }).catch(e=>{});
    }

    const addNewItem = ()=>{

        // the items meta is up to date at this point beacuse this function is called from inside a lambda function tha is created with each render so the closure of this call will contain the most up to date value
    
        if(itemsMeta && Array.isArray(itemsMeta.items))
        {
            let newItem = {id: itemsMeta.nextId, orderIdex:itemsMeta.items.length};
            let newMeta = JSON.parse(JSON.stringify(itemsMeta));
            newMeta.items.push(newItem);
            newMeta.nextId++;
            storeText(tagPrefix+".itemsMeta", JSON.stringify(newMeta)).then(()=>fetchItemsMeta());

        }
        else{
            storeText(tagPrefix+".itemsMeta", JSON.stringify({items:[{id:1, orderIdex:0}], nextId:2})).then(()=>fetchItemsMeta());

        }
        

    }

    const removeItem  = (listIndex)=>{
        let iMCopy = JSON.parse(JSON.stringify(itemsMeta));
        if(iMCopy)
        {
            if(iMCopy.items)
            {
                if(iMCopy.items.length>listIndex)
                {
                    let toBeRemoved = iMCopy.items[listIndex];
                    iMCopy.items.splice(listIndex,1);
                    if(iMCopy.items.length===0)
                    {
                        iMCopy.nextId = 1;
                    }
                    else{
                    iMCopy.nextId = iMCopy.items.reduce((acc, item)=>Math.max(acc, item.id+1),1);
                    }
                    clearAllByPrefix(tagPrefix+".item."+toBeRemoved.id).then(r=>{}).catch(e=>{});
                    storeText(tagPrefix+".itemsMeta", JSON.stringify(iMCopy)).then(()=>fetchItemsMeta());

                }
            }
        }
    }

    
    const generateItemList = ()=>{
        let items = [];

        if(itemsMeta  && Array.isArray(itemsMeta.items))
        for(let i =0; i<itemsMeta?.items.length;i++){
            let itemMeta = itemsMeta.items[i];
            let item = {};
            if(admin)
            {
                item["domElement"] =(
                    <div className="ic_asrto05_big"ref = {(r)=>item.ref = r} style = {{}}>
                        <div style ={{width:"100%", height:"calc(100% - 3rem"}}>
                            <P1T1S1FullViewport itemDeletionHandler = {()=>{removeItem(new Number(i))}}tagPrefix = {tagPrefix+".item."+itemMeta.id} admin={admin} imgWidth={600}>{i}</P1T1S1FullViewport>
                        </div>
                        <div  className = "flex flexRow flexJustifyCenter " style ={{width:"100%", height:"3rem", overflow:"hidden", padding:"0.2rem"}}> 
                            <div onClick = {()=>{removeItem(new Number(i))}} className="close"></div>
                        </div>
                    </div>);
            }
            else{
                item["domElement"] =(
                    <div className="ic_fullviewport"ref = {(r)=>item.ref = r} style = {{}}>
                        <div style ={{width:"100%", height:"100%"}}>
                            <P1T1S1FullViewport itemDeletionHandler = {()=>{removeItem(new Number(i))}}tagPrefix = {tagPrefix+".item."+itemMeta.id} admin={admin} imgWidth = {1000}>{i} </P1T1S1FullViewport>
                        </div>
                    
                    </div>);
            }
            items.push(item);    
            
        }
        if(admin)
        {
            let item = {};
            item["domElement"] =(
                <div className="ic_asrto05_big"ref = {(r)=>item.ref = r} style = {{}}>
                    <div className = "flex flexJustifyCenter flexAlignItemsCenter flexAlignContentCenter" style={{width:"3rem", height:"100%"}}>
                    <div className="add" onClick={()=>{addNewItem()}}></div>
                    </div>
                </div>);
            items.push(item);
        }
        return items;
    }
    return (
        <div className="fullParentWidth" style={{padding:"0"}}>
            { admin && 
                <div className="fullParentWidth flex flexColumn flexJustifyStart">
                     
                    <GenericItemCarousel items={generateItemList()}/>
                </div>
            }
            { !admin && 
                <div className="fullParentWidth flex flexColumn flexJustifyStart">
                    
                   <GenericItemCarousel items={generateItemList()}/>
                </div>
                
            }
        </div>
    );
}