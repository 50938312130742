import React, { useEffect, useState } from "react";
import "./../category/CategoryTreeStyle.css";
import CategoryNode from "./CategoryNode";
import { useNavigate } from "react-router-dom";
import { performHttpRequest } from "../../api/http/generic";


export default function CategoryTree({parentId, signal, selectionHandler}){

    let [categoryList, setCategoryList] = useState([]);
     
    useEffect(()=>{
        performHttpRequest("POST","/generic/category/filters?attributes=id",null, JSON.stringify({parentId:parentId?parentId:null}),(r,c,e,j)=>{
            setCategoryList(r);
        })
    },[parentId, signal]);


    const generateContent = ()=>{
        let result = [];
        for ( let index in categoryList)
        {
            result.push(
                <CategoryNode selectionHandler = {selectionHandler} categoryId={categoryList[index]?.id}/>
            );
        }
        return result;
    }
    return(
        <div className="categoryTreeMain flex flexNoWrap flexColumn flexJustifyStart">
            {generateContent()}
        </div>
    );
}
