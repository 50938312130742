import React, { useEffect, useRef, useState } from "react";
import "./ProductDashboardStyle.css";
import { performHttpRequest } from "../../api/http/generic";
import ProductPreview from "./ProductPreview";
import { useLocation, useNavigate } from "react-router-dom";

export default function ProductDashboard({categoryId, categoryName}){

    let [productList, setProductList] = useState([]);
    let cursor = useRef(null);
    let fetchMore = useRef(null);
    let [fetchDisabled, setFetchDisabled] = useState(false);

    useEffect(()=>{
         if(!fetchMore.current)
        {
                return ;
        }
            const observer = new IntersectionObserver(
                (entries) => {
                  entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                  
                      console.log('Button is visible!');
                      fetchNextProducts();
                     }
                  });
                },
                {
                  threshold: 0.1, // Adjust threshold as needed (percentage of visibility)
                }
              );
          
              
                observer.observe(fetchMore.current);
            
              return () => {
                // Clean up the observer when the component unmounts
                
                    if(fetchMore.current)
                  observer.unobserve(fetchMore.current);
                
              };
    
    },[categoryId]);

    useEffect(()=>{
        cursor.current = null;
        setProductList([]);
        fetchFirstProducts();
    }
    ,[categoryId]);

    useEffect(()=>{console.log(productList);
        
    
    },[productList]);
    const generateQuery = ()=>{
        let query = "?limit=16";
        if(cursor.current!==null && cursor.current!==undefined)
        {
            query+="&cursor="+cursor.current;
        }
        if(categoryId!==null && categoryId!==undefined)
        {
            query+="&categoryId="+categoryId;

        }
        console.log("query&&&&&",query);
        return query;
    };
    const fetchNextProducts = ()=>{
        if(!(cursor.current>0))
        return;
        if(fetchDisabled)
        return;
        setFetchDisabled(true);
        performHttpRequest("GET", "/product/dashboard"+generateQuery(),null,JSON.stringify(), (r,c,e,j)=>{
            if(c>=200 && c<=206)
            {
                if(r){
                    setProductList((old)=>{
                        if(Array.isArray(r)&& r.length>0)
                        {
                            cursor.current = r.reduce((min,el)=>{return (el.id>min?min:el.id)},(cursor.current?cursor.current:r[0]));
                            return [...old, ...r];
                        }
                        else return old;
                    })
                }
            }
            setTimeout(()=>setFetchDisabled(false),1000);
        })
    }
    const fetchFirstProducts = ()=>{

        performHttpRequest("GET", "/product/dashboard"+generateQuery(),null,JSON.stringify(), (r,c,e,j)=>{
            //if catagoryId in the moment of request is equal to the curre3nt state 
            if(c>=200 && c<=206)
            {
                if(r){
                    setProductList((old)=>{
                        if(Array.isArray(r)&& r.length>0)
                        {
                            cursor.current = r.reduce((min,el)=>{return (el.id>min?min:el.id)},(cursor.current?cursor.current:r[0]));
                             return r;
                        }
                        else return old;
                    })
                }
            }
        })
    }
    const generateContent = ()=>{
        let result = [];
        for(let index in productList)
        {
            result.push(<ProductPreview product={productList[index]}/>);
        }
        return result;
    };
    return(
        <div className="productDashboardMainWrapper flex flexColumn flexJustifyStart flexAlignItemsCenter flexAlignContentCenter">
            <div className="productDashboardCategoryInfo flex flexRow flexJustifyStart">
                <span>
                    {categoryName?".. "+ categoryName:""}
                </span>
            </div>
            <div className="productDashboardMain flex flexRow flexWrap flexJustifyStart">
                
                {generateContent()}
            </div>
            <button disabled = {fetchDisabled} className={"fetchMore "+(fetchDisabled?"loading":"")} ref = {fetchMore} onClick={()=>fetchNextProducts()}>+</button>

        </div>
    );

}