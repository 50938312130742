import React, { useEffect, useState } from "react";
import "./FlexGridStyle.css";
import { CENTER, SPACEEVENLY, START } from "./GridJustify";
import { NOWRAP, WRAP } from "./GridWrap";
import { getSuggestedQuery } from "@testing-library/react";
export default function FlexGrid({options, items}){


    let [justify, setJustify] = useState(CENTER);
    let [wrap, setWrap] = useState(WRAP);


useEffect(()=>{
    if(options)
    {
        if(options.justifyContent)
        {
            setJustify(options.justifyContent);
        }
        if(options.flexWrap)
        {
            setWrap(options.flexWrap);
        }
    }
},
[options]);
const generateContent = ()=>{
    let result = [];
    if(items && Array.isArray(items))
    {
        for(let index in items)
        {
            result.push(items[index]["domElement"]);
        }
    }
    return result;
}
    return (
        <div className="flexGridWrapper">
            <div className={"flexGridBody  flex flexRow   flexAlignItemsStretch flexAlignContentStretch "+ justify + " "+ wrap}>
                {generateContent()}
            </div>
        </div>
    );
}