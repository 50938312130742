import React, { useEffect, useState } from "react";
import { loadText, storeText } from "../../api/ObjectStoreApi";

export default function TwoLineBlank({admin, tagPrefix}){
    let [fC, setFC] = useState("");
    useEffect(()=>{
         setFC("");
        

    
        loadColor();
         },[tagPrefix, admin]);
         const loadColor = ()=>{
            loadText(tagPrefix+".C").then((color)=>setFC(color)).catch((e)=>{});
        }
        const storeColor = ()=>{
            storeText(tagPrefix+".C", fC);
        }
return(
    <div className="fullParentWidth" style={{height:(admin?"fit-content":"2rem"), backgroundColor:(admin?"":fC)}}>
             {admin && 
                <div className="admin">
                     <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter breakWordPretty maxWidthParent" style={{padding:"0.5rem"}}>
                        <div className="breakWordPretty maxWidthParent">Culoare fundal</div>
                        <input value={fC?fC:"#ffffff"}   type="color" onInput={(event)=>{console.log(event.target.value); setFC(event.target.value);}}/>
                        <div className="check" onClick={storeColor}/>
                    </div>
                  
                </div>}

    </div>
);
}