import {performHttpRequest} from "./../../api/http/generic";


async function  storeText(tag, data)
{
    return new Promise((resolve, reject)=>{
        try{
            performHttpRequest("POST", "/objectstore/text/"+tag, {["Content-Type"]:"text/plain"}, data, (r,c,e,j)=>{resolve(c);});
        }
        catch(e)
        {
            resolve(500);
        }
        }
    );
}
async function storeImage(tag, imageFile)
{
    return new Promise((resolve, reject)=>{
        try{
            const formData = new FormData();
            formData.append('image', imageFile);
            performHttpRequest("POST", "/objectstore/image/"+tag, {["Content-Type"]:null}, formData, (r,c,e,j)=>{resolve(c);});
        }catch(e)
        {
            resolve(500);
        }
    });
}
async function  loadText(tag)
{
    return new Promise((resolve, reject)=>{
            try{
                performHttpRequest("GET", "/objectstore/text/"+tag, null, null, (r,c,e,j)=>{
                    if(c>=200 && c<=206)
                    {
                        resolve(r); 
                    }
                    else{
                        reject(e);
                    }
                });
            }
            catch(e)
            {
                reject("Internal application error");
            }
        }
    );
}

async function  clearText(tag)
{
    return new Promise((resolve, reject)=>{
            try{
                performHttpRequest("DELETE", "/objectstore/text/"+tag, null, null, (r,c,e,j)=>{
                    if(c>=200 && c<=206)
                    {
                        resolve(r); 
                    }
                    else{
                        reject(e);
                    }
                });
            }
            catch(e)
            {
                reject("Internal application error");
            }
        }
    );
}

async function  clearImage(tag)
{
    return new Promise((resolve, reject)=>{
            try{
                performHttpRequest("DELETE", "/objectstore/image/"+tag, null, null, (r,c,e,j)=>{
                    if(c>=200 && c<=206)
                    {
                        resolve(r); 
                    }
                    else{
                        reject(e);
                    }
                });
            }
            catch(e)
            {
                reject("Internal application error");
            }
        }
    );
}
async function  clearTextByPrefix(tag)
{
    return new Promise((resolve, reject)=>{
            try{
                performHttpRequest("DELETE", "/objectstore/text/prefix/"+tag, null, null, (r,c,e,j)=>{
                    if(c>=200 && c<=206)
                    {
                        resolve(r); 
                    }
                    else{
                        reject(e);
                    }
                });
            }
            catch(e)
            {
                reject("Internal application error");
            }
        }
    );
}

async function  clearImageByPrefix(tag)
{
    return new Promise((resolve, reject)=>{
            try{
                performHttpRequest("DELETE", "/objectstore/image/prefix/"+tag, null, null, (r,c,e,j)=>{
                    if(c>=200 && c<=206)
                    {
                        resolve(r); 
                    }
                    else{
                        reject(e);
                    }
                });
            }
            catch(e)
            {
                reject("Internal application error");
            }
        }
    );
}
async function clearAllByPrefix(tag){
    try{
        await clearTextByPrefix(tag);
        await clearImageByPrefix(tag);
        return true;
    }catch(e){
        throw new Error("Error clearing");
    }
}


export {storeText, loadText, storeImage, clearImage, clearText, clearTextByPrefix, clearImageByPrefix, clearAllByPrefix}