
class AnimationEngine {
    constructor(){
        this.lastTimestamp = 0;
        this.views = [];
        this.running = false;
  
    
    };


     animate(timestamp)
    {
        //console.log("New update begin");
        let delta = timestamp - this.lastTimestamp;
        this.lastTimestamp = timestamp;
        for(let index in this.views)
        {
            this.views[index].update(delta);
        }
        if(this.running){
        requestAnimationFrame((timestamp)=>this.animate(timestamp));
        }

    }


    start(){
        this.running = true;
        requestAnimationFrame((timestamp)=>this.animate(timestamp));
    }
    stop(){
        this.running = false;
    }

    addView(view)
    {
        this.views.push(view);
    }
    removeView(view)
    {
        this.views.remove(view);
    }
}

let engine = new AnimationEngine();
engine.start();
export default engine;