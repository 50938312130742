import React, { useEffect, useState } from "react";
import { performHttpRequest } from "../../api/http/generic";

import "./AdminCategoryStyle.css";
import CategoryTable from "./CategoryTable";
import Modal from "../../genericComponents/modal/Modal";
import AddCategoryForm from "./AddCategoryForm";
export default function AdminCategory({notifyParent}){

    let [categoryStatistics, setCategoryStatisctics] = useState({});
    let [modalOpen, setModalOpen] = useState(false);
    let [tableSignal, setTableSignal] = useState(false);
useEffect(()=>{

    fetchStatistics();
  
},[]);

    const fetchStatistics = ()=>{
        performHttpRequest("GET", "/category/statistics", null, null, (r,c,e,j)=>{
            if(c>=200 && c<=206)
            {
                if(r)
                {
                    setCategoryStatisctics(r);
                }
            }
        });
    }


    const generateCategoryStatisctics = ()=>{
        let result = [];
        if(categoryStatistics?.numberOfCategories>=0)
        {
            result.push(
                <div className="adminCategoryStatisticsItem flex flexRow flexJustifyCenter flexAlignContentEnd flexAlignItemsEnd">
                     <div className="bigText"> {categoryStatistics.numberOfCategories}</div>
                     <div className="lightText">&nbsp;{(categoryStatistics?.numberOfCategories===1)?"categorie":"categorii"}</div>
                </div>
            );
        }
        if(categoryStatistics?.numberOfRootCategories>=0)
        {
            result.push(
            <div className="adminCategoryStatisticsItem flex flexRow flexJustifyCenter flexAlignContentEnd flexAlignItemsEnd">
            <div className="bigText"> {categoryStatistics.numberOfRootCategories}</div>
            <div className="lightText">&nbsp;{(categoryStatistics?.numberOfRootCategories===1)?"principala":"principale"}</div>
            </div>
            );
        }
        if(categoryStatistics?.numberOfLeafCategories>=0)
        {
            result.push(
            <div className="adminCategoryStatisticsItem flex flexRow flexJustifyCenter flexAlignContentEnd flexAlignItemsEnd">
            <div className="bigText"> {categoryStatistics.numberOfLeafCategories}</div>
            <div className="lightText">&nbsp;{(categoryStatistics?.numberOfLeafCategories===1)?"finala":"finale"}</div>
            </div>
            );
        }
        return result;
        
    }

    return(
    <div className="adminCategoryMain fullParentWidth">
         
        <div className="adminCategoryTableWrapper">
            <div className="adminCategoryStatistics flex flexRow flexJustifyStart flexAlignContentStart flexAlignItemsCenter flexWrap">
          
                {generateCategoryStatisctics()}
             
            </div>
        </div>
         <div className="adminCategoryTableWrapper">
        <div className=" adminSpecificCategoryPreTable flex flexRow flexWrap flexJustifySpaceBetween flexAlignContentCenter flexAlignItemsCenter">
            <div className="adminSpecificCategoryTableTitle">Categorii principale:</div>
            <div onClick = {()=>{setModalOpen(true)}}className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexAlignContentCenter flexAlignItemsCenter">
                <svg  className="adminSpecificCategoryAddCategoryIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                <line x1="50%" y1="5%" x2="50%" y2="95%"></line>
                <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                </svg>
                <div className="lightText">adauga</div>
            </div>
        </div>

            <CategoryTable signal = {tableSignal}/>
        </div>

        <Modal closeFunction = {()=>setModalOpen(false)} isOpen={modalOpen} element = {<AddCategoryForm closeFunction = {()=>{setModalOpen(false); fetchStatistics(); setTableSignal(!tableSignal);notifyParent();}} title = "Adaugare categorie"/>}/>
    </div>
    );
}