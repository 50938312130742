import Footer from "../../final/footer/Footer";
import Poster from "../../final/item/Poster";
import FullScreenItemCarousel from "../../final/itemContainer/FullScreenItemCarousel";
import SimpleFatItemCarousel from "../../final/itemContainer/SimpleFatItemCarousel";
import SimpleInfoGrid from "../../final/itemContainer/SimpleInfoGrid";
import SimpleItemCarousel from "../../final/itemContainer/SimpleItemCarousel";
import SimpleWideItemCarousel from "../../final/itemContainer/SimpleWideItemGrid";
import TwoLineBlank from "../../final/spacing/twoLineBlank";
import Topbar from "../../final/topbar/Topbar";
import b1 from "./1.png";
import b2 from "./2.png";
import b3 from "./3.png";
import b4 from "./4.png";
import b5 from "./5.png";
import b6 from "./6.png";
import b7 from "./7.png";
import b8 from "./8.png";
import b9 from "./9.png";

const   ComponentsMetaDataset = {
    "1":{
        id:1,
        orderIndex:1,
        title:"Carousel - elemente mici",
        description:"un rand navigabil de elemente mici care contin o imagine, un titlu si un subtitlu",
        image:`${b1}`,
        getComponent:(properties)=>{return (<SimpleItemCarousel  {...properties}/>)}
    },
    "4":{
        id:2,
        orderIndex:2,
        title:"Carousel - elemente mari",
        description:"un rand navigabil de elemente mari care contin o imagine, un titlu si un subtitlu",
        image:`${b2}`,
        getComponent:(properties)=>{return (<SimpleFatItemCarousel {...properties}/>)}
    },
    "8":{
        id:2,
        orderIndex:3,
        title:"Carousel - elemente pe intreg ecranul",
        description:"un rand navigabil de elemente sub forma de 'elementul Poster' ",
        image:`${b3}`,
        getComponent:(properties)=>{return (<FullScreenItemCarousel   {...properties}/>)}
    },
    "6":{
        id:2,
        orderIndex:4,
        title:"Poster",
        description:"element mare, relativ pe intreg ecranul, compus dintr-o imagine, un titlu, un subtitlu si un buton",
        image:`${b4}`,
        getComponent:(properties)=>{return (<Poster   {...properties}/>)}
    },
    "5":{
        id:2,
        orderIndex:5,
        title:"Grila - elemete mici  ",
        description:"o grila flexibila, compusa din elemente alcatuite dintr-o imagine, un titlu si un subtitlu acestea suprapunandu-se peste imagine",
        image:`${b5}`,
        getComponent:(properties)=>{return (<SimpleWideItemCarousel   {...properties}/>)}
    },

    "7":{
        id:2,
        orderIndex:6,
        title:"Grila - elemente informative ",
        description:"o grila flexibila, compusa din elemente alcatuite dintr-o imagine afisata mic, sub forma de iconita, un titlu si un subtitlu",
        image:`${b6}`,
        getComponent:(properties)=>{return (<SimpleInfoGrid   {...properties}/>)}
    },
    "9":{
        id:2,
        orderIndex:7,
        title:"Bara de actiuni",
        description:"bara de actiuni, pentru care se poate incarca imaginea cu logo",
        image:`${b7}`,
        getComponent:(properties)=>{return (<Topbar   {...properties}/>)}
    },
    "2":{
        id:2,
        orderIndex:8,
        title:"Antet de pagina",
        description:"element dedicat zonei de antet",
        image:`${b8}`,
        getComponent:(properties)=>{return (<Footer   {...properties}/>)}
    },
    "3":{
        id:2,
        orderIndex:9,
        title:"Spatiere",
        description:"spatiere de doua linii",
        image:`${b9}`,
        getComponent:(properties)=>{return (<TwoLineBlank {...properties}/>)}
    },
  
 
  
 
};

export default ComponentsMetaDataset;