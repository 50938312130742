import React, { useEffect, useRef, useState } from "react";

import "./SwipeContainerStyle.css";
import ScrollableElement from "./ScrollableElement";

export default function SwipeContainer({test_nrOfEl, elementsInput}){

    let elementsRefs = useRef({sortedList:[]});
    let mainContainerRef = useRef(null);
    //let [curentElementIndex, setCurentElementIndex] = useState(0);
    let curentElementIndex = useRef(0);
    let preparingStage = useRef(false);

    let eventHandlerDelegated = useRef({onWheel: false});
    let eventHandlerDelegationDeactivator = useRef({onWheel: null});


    let touchLastPostion = useRef({x:0, y:0});

    useEffect(()=>{
        let evL = (e)=>onWheelHandler(e, "document");
        let tsL = (e)=>onTouchStart(e);
        let tmL = (e)=>onTouchMove(e);
        let teL = (e)=>onTouchEnd(e);

        document.addEventListener('wheel',evL);


        document.addEventListener("touchstart", tsL);
        document.addEventListener("touchmove", tmL, {passive:false});
        document.addEventListener("touchend", teL);
        document.addEventListener("swipe-up", ()=>console.log("swipeUp"));


        return      ()=>   {
            document.removeEventListener('wheel', evL);
            document.removeEventListener("touchstart",tsL);
            document.removeEventListener("touchmove", tmL);
            document.removeEventListener("touchend", teL);
        }

         
    },[test_nrOfEl]);
    useEffect(()=>{
       // console.log(curentElementIndex);
        displayElement(curentElementIndex.current);         
    },[test_nrOfEl]);
   const generateElements = ()=>{

        elementsRefs.current = {sortedList:[]};
        let result = [];
     
        for(let index in elementsInput)
        {
           
            let key = new String(index);
            let element = {identifier: index, handler:{}};
            elementsRefs.current[key] = element ;


            let inputElement = React.cloneElement(elementsInput[index], {registerHandler:  (eventType, handler)=>{
                element.handler[eventType] = handler;
            }});
            result.push(
                <div className="elementContainer" ref={
                    (r)=>{
                        if(r){
                            element.element = r;
                            //sort
                            //sort not needed for this implementation, maybe in future implementation if the defaul order of the flow is not the desired one, but and the position of the elements is different from the order the elements are pushed as content, then use the sortedList
                            //use the list nbut the sorted [] is the same as this order of i
                            sortElementsRefs();
                            //console.log(elementsRefs.current);
                        }
                    }
                }
                styleex={{background: "rgb("+Math.random()*255+","+Math.random()*255+","+Math.random()*255+")"}}
               >
                {inputElement}
                </div>
            );
         }
        return result;
    }
    const onWheelHandler  = (e, element)=>{

        /*
        if(element)
        {
           // console.log("inside element, ", element);
        }
      //  console.log(e.deltaX, e.deltaY);

        if(mainContainerRef && mainContainerRef.current)
        {
           // console.log(mainContainerRef.current.getBoundingClientRect(), mainContainerRef.current.scrollHeight);
        }
        */
        if(elementsRefs && elementsRefs.current.sortedList)
        {
            if(!preparingStage.current)
            {
                let element = elementsRefs.current.sortedList[curentElementIndex.current];
                let delegationUsed = false;
                if(element && element.handler && element.handler.onWheel)
                {
                delegationUsed = element.handler.onWheel(e);
                }
                if(delegationUsed)
                {
                    eventHandlerDelegated.current.onWheel = true;
                    if(eventHandlerDelegationDeactivator.current.onWheel){
                        clearTimeout(eventHandlerDelegationDeactivator.current.onWheel);
                        eventHandlerDelegationDeactivator.current.onWheel = null;
                    }
                    eventHandlerDelegationDeactivator.current.onWheel = setTimeout(()=>{
                        eventHandlerDelegated.current.onWheel = false;
                        eventHandlerDelegationDeactivator.current.onWheel = null;
                    },1100);

                }

                else{
                    if(!eventHandlerDelegationDeactivator.current.onWheel)
                    {
                        eventHandlerDelegationDeactivator.current.onWheel = setTimeout(()=>{
                            eventHandlerDelegated.current.onWheel = false;
                            eventHandlerDelegationDeactivator.current.onWheel = null;
                        },1100);
                    }
                }


                if(!eventHandlerDelegated.current.onWheel){
                    if(!preparingStage.current)
                    {
                        
                        if(e.deltaY<0)
                        {
                            if(curentElementIndex.current>0){
                                //setCurentElementIndex(curentElementIndex-1);
                                curentElementIndex.current--;                
                                displayElement(curentElementIndex.current);
                                preparingStage.current =true;
                                setTimeout(()=> {preparingStage.current = false}, 1100);

                            }
                        }
                        if(e.deltaY>0)
                        {
                            if(curentElementIndex.current<elementsRefs.current.sortedList.length-1){
                                curentElementIndex.current++;
                                displayElement(curentElementIndex.current);
                                preparingStage.current =true;
                                setTimeout(()=> {preparingStage.current = false}, 1100);

                            }
                        }
                    }
                }
            }

        }
    }


    const onTouchStart = (e)=>{
       // console.log("Touch start", e);

        touchLastPostion.current = {x:e.touches[0].clientX, y:e.touches[0].clientY};
    }
    const onTouchMove = (e)=>{
        e.preventDefault();
       // console.log("Touch move", e);
        let x = e.touches[0].clientX;
        let y = e.touches[0].clientY;

        let deltaX = touchLastPostion.current.x-x;
        let deltaY = touchLastPostion.current.y-y;

        touchLastPostion.current = {x:e.touches[0].clientX, y:e.touches[0].clientY};


        onWheelHandler({deltaX:deltaX, deltaY:deltaY});

    }
    const onTouchEnd = ()=>{
       // console.log("Touch end");
    }
    const sortElementsRefs =()=>{
        elementsRefs.current.sortedList = [];
        if(elementsRefs && elementsRefs.current)
        {
            if(Object.keys(elementsRefs.current).length>1)
            {
                for(let key in elementsRefs.current)
                {
                    if(key!==("sortedList"))
                    {
                        elementsRefs.current.sortedList.push(elementsRefs.current[key]);
                    }
                }
                //or ort by identifier// tjis is why used the identifierr
                elementsRefs.current.sortedList.sort((a,b)=>{if(a && b && a.identifier && b.identifier && (a.identifier<b.identifier)){return -1} else{return 1}})

               // elementsRefs.current.sortedList.sort((a,b)=>{if(a && b && a.element && b.element && (a.element.clientTop<b.clientTop)){return -1} else{return 1}})
            }

        }
    }

    const displayElement = (targetIndex)=>{
      for(let index in elementsRefs.current.sortedList)
      {
        let element = elementsRefs.current.sortedList[index];
        if(element && element.element){
            element.element.classList.remove("exitBefore");
            element.element.classList.remove("exitAfter");
            element.element.classList.remove("onStage");

            element.element.classList.add((index<targetIndex?"exitAfter":(index>targetIndex?"exitBefore":"onStage")));
        }

      }
    }
    return (
        <div  ref = {mainContainerRef} onWheel= {onWheelHandler}className="swipeContainerMain">

            {generateElements()}

        </div>
        );
}