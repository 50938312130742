import React, { useEffect, useState } from "react";
import { performHttpRequest } from "../../api/http/generic";

import "./../category/AdminSpecificCategoryStyle.css";
import ProductTable from "./ProductTable";
import { useParams, useNavigate } from "react-router-dom";
import  AddProductForm from "./AddProductForm";
import Modal from "./../../genericComponents/modal/Modal"
import DeleteProductForm from "./DeleteProductForm";
import ProductTree from "./ProductTree";
import ProductImageAdmin from"./ProductImageAdmin";
import Quill from "quill";
  
export default function AdminSpecificProduct(){

    let [category, setCategory] = useState({});
    let [modalConfig, setModalConfig] = useState({isOpen:false, element:{}});
    let [tableSignal, setTableSignal] = useState(false);
    let [categoryList, setCategoryList] = useState([]);
    let [fetchedCategoryList, setFetchedCategoryList] = useState([]);

    let parameters = useParams();

  
   
useEffect(()=>{

    
    fetchCategory();
},[parameters]);
 useEffect(()=>{
    if(category && category.Categories && Array.isArray(category.Categories))
    {
        let result = [];
        for(let index in category.Categories)
        {
            result.push(category.Categories[index]?.id)
            setFetchedCategoryList(result);
            setCategoryList(result);
        }
    }},[category]);

 const selectCategoryHandler = (list, notInterested, reset)=>{

    setCategoryList((oldValue)=>{
        let newValue = [];
        if(reset)
        {
            for(let index in oldValue)
            {
                if(!(list.includes(oldValue[index])))
                {
                    newValue.push(oldValue[index]);
                }
            }
        }
        else{
            newValue = [...oldValue];
            for(let index in list)
            {
                if(!(oldValue.includes(list[index])))
                {
                    newValue.push(list[index]);
                }
            }

        }
        return newValue;
    });

 }
 const saveCategories =()=>{
    performHttpRequest("PUT", "/product/setCategories", null, JSON.stringify({id:category?.id, categoryList:categoryList}), (r,c,e,j)=>{
        fetchCategory();
    });
 }
const fetchCategory = ()=>{
    performHttpRequest("GET", "/generic/product/"+parameters.productId+"?references=category;id-.", null, null, (r,c,e,j)=>{
        if(c>=200 && c<=206)
        {
            if(r)
            {
                setCategory(r);
            }
        }
    });
}
 /*
    const generateCategoryStatisctics = ()=>{
        let result = [];
        if(categoryStatistics?.numberOfCategories>=0)
        {
            result.push(
                <div className="adminCategoryStatisticsItem flex flexRow flexJustifyCenter flexAlignContentEnd flexAlignItemsEnd">
                     <div className="bigText"> {categoryStatistics.numberOfCategories}</div>
                     <div className="lightText">&nbsp;{(categoryStatistics?.numberOfCategories===1)?"categorie":"categorii"}</div>
                </div>
            );
        }
        if(categoryStatistics?.numberOfRootCategories>=0)
        {
            result.push(
            <div className="adminCategoryStatisticsItem flex flexRow flexJustifyCenter flexAlignContentEnd flexAlignItemsEnd">
            <div className="bigText"> {categoryStatistics.numberOfRootCategories}</div>
            <div className="lightText">&nbsp;{(categoryStatistics?.numberOfRootCategories===1)?"principala":"principale"}</div>
            </div>
            );
        }
        if(categoryStatistics?.numberOfLeafCategories>=0)
        {
            result.push(
            <div className="adminCategoryStatisticsItem flex flexRow flexJustifyCenter flexAlignContentEnd flexAlignItemsEnd">
            <div className="bigText"> {categoryStatistics.numberOfLeafCategories}</div>
            <div className="lightText">&nbsp;{(categoryStatistics?.numberOfLeafCategories===1)?"finala":"finale"}</div>
            </div>
            );
        }
        return result;
        
    }
*/

const updateProduct = (key, value)=>
{
    setCategory((old)=>{
            let updated = JSON.parse(JSON.stringify(old));
            updated[key] = value;
            return updated;
            
    });
}
const saveProductAttribute = (key)=>{
    performHttpRequest("PUT", "/generic/product/"+category?.id, null, JSON.stringify({[key]:category[key]}),(r,c,e,j)=>{
        fetchCategory();
    });
}
let getModalElement  = ()=>{
    switch(modalConfig.element)
    {
        case "delete": return <DeleteProductForm title={"Stergere produs"} category={category} closeFunction={()=>setModalConfig({isOpen:false, element:{}})}/>
    }
}
    return(
    <div className="adminSpecificCategoryMain fullParentWidth">

        <div className="locationInfo lightText">Administrare produs {category?.title}</div>

         <div className="adminSpecificCategoryTableWrapper">
            <div className="adminSpecificCategoryInfo fullParentWidth flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter">
                 
            <textarea className=" adminSpecificProductInput bigText" onInput={(event)=>updateProduct("title", event.target.value)} value={category?.title}/>
                
                <div onClick = {()=>{saveProductAttribute("title")}} className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexAlignContentCenter flexAlignItemsCenter">
                   
                    <svg  className="adminSpecificCategoryAddCategoryIcon" height="256.000000pt" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 256.000000 256.000000" width="256.000000pt" xmlns="http://www.w3.org/2000/svg"><g fill="#000000" stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"><path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z"/></g></svg>


                    <div className="lightText">salveaza titlu</div>
                </div>
                <div onClick = {()=>{setModalConfig({isOpen:true, element:"delete"})}}className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexAlignContentCenter flexAlignItemsCenter">
                    <svg  className="adminSpecificCategoryAddCategoryIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                         <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                    </svg>
                    <div className="lightText">sterge produs</div>
                </div>
            </div>   
        </div>   
        <div className="adminSpecificCategoryTableWrapper">
            <div className="adminSpecificCategoryInfo fullParentWidth flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter">
                 
                <textarea className=" adminSpecificProductInput bigText lightText" onInput = {(event)=>updateProduct("subtitle", event.target.value)}value={category?.subtitle}/>
                <div onClick = {()=>{saveProductAttribute("subtitle")}}className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexAlignContentCenter flexAlignItemsCenter">
                   
                <svg  className="adminSpecificCategoryAddCategoryIcon" height="256.000000pt" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 256.000000 256.000000" width="256.000000pt" xmlns="http://www.w3.org/2000/svg"><g fill="#000000" stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"><path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z"/></g></svg>


                    <div className="lightText">salveaza subtitlu</div>
                </div>
                
            </div>   
        </div>  
        <div className="adminSpecificCategoryTableWrapper">
            <div className="adminSpecificCategoryInfo fullParentWidth flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter">
                 
                <textarea className=" adminSpecificProductHighInput" onInput={(event)=>updateProduct("description", event.target.value)} value={category?.description}/>
                {/*<div ref ={willDo} id = "descriptionEditor" style ={{width:"100%", height:"70vh"}}/>*/}
                
                <div onClick = {()=>{saveProductAttribute("description")}}className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexAlignContentCenter flexAlignItemsCenter">
                   
                <svg  className="adminSpecificCategoryAddCategoryIcon" height="256.000000pt" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 256.000000 256.000000" width="256.000000pt" xmlns="http://www.w3.org/2000/svg"><g fill="#000000" stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"><path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z"/></g></svg>


                    <div className="lightText">salveaza descriere</div>
                </div>
                
            </div>   
        </div>  

        <div className="adminSpecificCategoryTableWrapper">
            <div className="adminSpecificCategoryInfo fullParentWidth flex flexColumn flexWrap flexJustifyStart flexAlignContentStart flexAlignItemsStart ">
                    
            <ProductTree selectedCategoryList = {fetchedCategoryList} selectionHandler={(a,b,c)=>{console.log(a,b,c); selectCategoryHandler(a,b,c)}}/>            
            <div onClick = {()=>{saveCategories()}} className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexAlignContentCenter flexAlignItemsCenter">
                    
                    <svg  className="adminSpecificCategoryAddCategoryIcon" height="256.000000pt" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 256.000000 256.000000" width="256.000000pt" xmlns="http://www.w3.org/2000/svg"><g fill="#000000" stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"><path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z"/></g></svg>


                        <div className="lightText">salveaza categoriile</div>
                    </div>
                
            </div>   
        </div>
        <div className="adminSpecificCategoryTableWrapper">
            <div className="adminSpecificCategoryInfo fullParentWidth flex flexColumn flexWrap flexJustifyStart flexAlignContentStart flexAlignItemsStart ">
                    
                <ProductImageAdmin productId={category?.id}/>
                
            </div>   
        </div>
        <Modal closeFunction = {()=>setModalConfig({isOpen:false})} isOpen={modalConfig.isOpen} element = 
        {getModalElement()}/>

          
        
    </div>
    );
}