import React, { useRef, useState } from "react";
import "./FileSelectorStyle.css"
 export default function FileSelector({uploadFiles})
{
    let [files, setFiles] = useState(null);
    let selectFilesInputReference = useRef();

const handleDragOver = (event)=>
{
    event.preventDefault();
}
const handleDrop = (event)=>
{
    event.preventDefault();
    //console.log(Array.from(event.dataTransfer.files));
    setFiles(Array.from(event.dataTransfer.files));
}
const generateFilesContent = ()=>
{
    let result = [];
    for (let index in files)
    {
        result.push(
            <div className="fileContainer">
            <div className="file">

            </div>
            <div className="fileName">{files[index].name}</div>
            </div>
        );
    }
    if(result.length===0){
        result.push("Drop files or click here");
    }
    return result;
}
    return (
        <div className="fileSelectorMainDiv">
            <div onClick= {()=>selectFilesInputReference.current.click()} onDrop = {handleDrop} onDragOver={handleDragOver} className="dropArea">
            
                {generateFilesContent()}
            </div>
            <input hidden ref = {selectFilesInputReference} type="file" multiple onChange = {(event)=>setFiles(Array.from(event.target.files))}/>
           {/* <button onClick={()=>selectFilesInputReference.current.click()}>Selecteaza imagine</button>*/}
            <button onClick={()=>{uploadFiles(files); setFiles(null);}}>Salveaza</button>
        </div>
    );

}