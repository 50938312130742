import React, { useState } from "react";
import "./../category/AddCategoryFormStyle.css";
import { performHttpRequest } from "../../api/http/generic";

export default function AddProductForm({title, parentId, closeFunction})
{


    let [name, setName] = useState("");
    let [subtitle, setSubtitle] = useState("");

    const addNewCategory = ()=>{
        performHttpRequest("POST", "/generic/product", null, JSON.stringify({title:name, subtitle:subtitle}),(r,c,e,j)=>{
                if(c>=200 && c<=206)
                {
                    //alert("SUCCESS");
                    closeFunction();
                }
        });
    }
    return (
        <div className="addCategoryFormMain flex flexColumn flexJustifySpaceBetween flexAlignContentCenter flexAlignItemsCenter">
            <div className="addCategoryFromHeader boldText ">{title?title:""}</div>
            <div className="addCategoryFormBody flex flexColumn flexJustifyStart">

                <div className="">Titlu</div>
                <input  onInput = {(event)=>{setName(event.target.value)}} value={name} type="text"/>
                <div className="">Subtitlu</div>
                <input  onInput = {(event)=>{setSubtitle(event.target.value)}} value={subtitle} type="text"/>

                 

            </div>
            <div className="addCategoryFormFooter flex flexRow flexWrap flexJustifySpaceEvenly">
                <button onClick={()=>addNewCategory()}>Adauga</button>
                <button onClick={()=>closeFunction()}>Renunta</button>

            </div>
        </div>
    );
}