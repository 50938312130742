import React from "react";
import "./ProductDashboardStyle.css"
import HOST from "../../configuration/backend/host";
import PROXY from "../../configuration/proxy/proxy";
import { useNavigate } from "react-router-dom";


export default function ProductPreview({product}){
    let redirectTo = useNavigate();

    const getBackgroundURL = ()=>{
        //if no image load all images if no image then, no image for the product from static resouces (public)
    }
    return (
    <div onClick = {()=>{redirectTo("./"+product?.id)}} className="productPreviewMain flex flexColumn flexJustifyStart flexAlignContentCenter flexAlignItemsCenter">

        <div className = "image" style={{backgroundImage:"url("+HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/image/"+product?.image+"?width=500)"}}>

        </div>
        <div className="title flex flexColumn flexNoWrap flexJustifyStart   flexAlignItemsStart flexAlignContentStart">
            <div>{product?.title}</div>
            <div>{product?.subtitle}</div>

        </div>
    </div>);
}