import React, { useEffect, useState } from "react";
import "./CategoryTreeStyle.css";
import {performHttpRequest} from "./../../api/http/generic";
import CategoryTree from "./CategoryTree";
import { redirect } from "react-router-dom";
export default function CategoryNode({categoryId, selectionHandler}){

    let [category, setCategory] = useState(null);
    let [explore, setExplore] = useState({open:false, build:false});
    

    useEffect(()=>{
        performHttpRequest("POST", "/category/filterWithStatistics?arguments=id,name",null, JSON.stringify({id:categoryId}), (r,c,e,j)=>{
            if(c>=200 && c<=206)
            {
                if(r)
                {
                    if(Array.isArray(r) && r.length>0)
                    {
                        setCategory(r[0]);
                    }
                    else{
                        setCategory(r);
                    }
                }
            }
        })
        {

        }
    },[categoryId]);

    const toogleExplore = ()=>{
      setExplore({
        open: !explore.open,
        build:true
      });
    }
    return (
        <div className="categoryNodeMain">
            <div className="categoryNodeTitle flex flexRow flexNoWrap flexJustifySpaceBetween">
                <div  onClick={()=>selectionHandler?selectionHandler(category?.id, category?.name):null} className="categoryNodeName boldText">{category?.name}</div>

                {category&&category.numberOfChildren>0 && <div onClick={()=>toogleExplore()} className="categoryNodeExplore"> 
                {(explore.open)?(<svg  className="categoryNodeExpandIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                 <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                </svg>):(<svg  className="categoryNodeExpandIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                <line x1="50%" y1="5%" x2="50%" y2="95%"></line>
                <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                </svg>)}
                </div>}
            </div>
            <div className={"categoryNodeChildren " + ((explore.open)?"open":"close")}>
                {category && category.id && explore.build && <CategoryTree selectionHandler = {selectionHandler}parentId={category?.id}/>}
            </div>
        </div>
    );

}