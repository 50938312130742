import React, { useEffect, useState } from "react";
import { Route, Routes, redirect, Navigate, useNavigate } from "react-router-dom";
 import AdminSpecificCategory from "./category/AdminSpecificCategory";
import "./adminStyle.css"
import CategoryAdmin from "./category/CategoryAdmin";
import ProductAdmin from "./product/ProductAdmin";
import AdminSpecificProduct from "./product/AdminSpecificProduct";
import UserProvider from "../userProvider/UserProvider";
import AdminAdmin from "./admins/AdminAdmin";
import ColumnComponentContainer from "../genericComponents/componentContainer/ColumnComponentContainer";
import ColumnComponentContainer_1Hardcoded from "../genericComponents/componentContainer/ColumnComponentContainer_1Hardcoded";
import ProductRouter from "../client/product/ProductRouter";
export default function Admin({}){
    let [optionsOpen, setOptionsOpen] = useState(false);
    let redirectTo = useNavigate();

    useEffect(()=>{
        UserProvider.refresh().then((r)=>{if(r&&r.admin){}else{redirectTo("/")}}).catch((e)=>redirectTo("/"));
    },[]);
    return (<>
        <div className="adminTopBarWrapper">
            <div className="adminTopBar flex flexJustifySpaceBetween flexAlignItemsCenter flexAlignContentCenter">
                <div className="bigText">Admin</div>
                <div className="adminTopBarOptionsActivator" onClick={()=>setOptionsOpen(!optionsOpen)}>
    
                <svg className="adminTopBarOptionsActivatorIcon" fill="#ffffff" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M7.49999 3.09998C7.27907 3.09998 7.09999 3.27906 7.09999 3.49998C7.09999 3.72089 7.27907 3.89998 7.49999 3.89998H14.5C14.7209 3.89998 14.9 3.72089 14.9 3.49998C14.9 3.27906 14.7209 3.09998 14.5 3.09998H7.49999ZM7.49998 5.1C7.27907 5.1 7.09998 5.27908 7.09998 5.5C7.09998 5.72091 7.27907 5.9 7.49998 5.9H14.5C14.7209 5.9 14.9 5.72091 14.9 5.5C14.9 5.27908 14.7209 5.1 14.5 5.1H7.49998ZM7.1 7.5C7.1 7.27908 7.27909 7.1 7.5 7.1H14.5C14.7209 7.1 14.9 7.27908 14.9 7.5C14.9 7.72091 14.7209 7.9 14.5 7.9H7.5C7.27909 7.9 7.1 7.72091 7.1 7.5ZM7.49998 9.1C7.27907 9.1 7.09998 9.27908 7.09998 9.5C7.09998 9.72091 7.27907 9.9 7.49998 9.9H14.5C14.7209 9.9 14.9 9.72091 14.9 9.5C14.9 9.27908 14.7209 9.1 14.5 9.1H7.49998ZM7.09998 11.5C7.09998 11.2791 7.27907 11.1 7.49998 11.1H14.5C14.7209 11.1 14.9 11.2791 14.9 11.5C14.9 11.7209 14.7209 11.9 14.5 11.9H7.49998C7.27907 11.9 7.09998 11.7209 7.09998 11.5ZM2.5 9.25003L5 6.00003H0L2.5 9.25003Z" fill="currentColor" fill-rule="evenodd"/></svg>

                    
                    { optionsOpen && <div className="adminTopBarOptions">
                        <div className="boldText adminOption" onClick = {()=>redirectTo("./category")}>Categorii</div>
                        <div className="boldText adminOption" onClick = {()=>redirectTo("./product")}>Produse</div>
                        <div className="boldText adminOption" onClick = {()=>redirectTo("./home")}>Pagina principala</div>
                        <div className="boldText adminOption" onClick = {()=>redirectTo("./productDashboard")}>Pagina produse</div>
                        <div className="boldText adminOption" onClick = {()=>redirectTo("./productPage")}>Pagina produs</div>

                        <div className="boldText adminOption" onClick = {()=>redirectTo("./admin")}>Administratori</div>

                    </div>}
                </div>
                
            </div>
        </div>
                    <Routes>
                        <Route path = {"/"} element = {<div><Navigate to="/admin/login"/></div>}></Route>
                        <Route path = {"/login"} element = {<div>Admin login</div>}></Route>
                        <Route path = {"/test/io"} element = {<div>Admin test</div>}></Route>

                        <Route path = {"/category"} element = {<CategoryAdmin/>}></Route>
                        <Route path = {"/category/:categoryId"} element = {<AdminSpecificCategory/>}></Route>

                        <Route path = {"/product"} element = {<ProductAdmin/>}></Route>
                        <Route path = {"/product/:productId"} element = {<AdminSpecificProduct/>}></Route>

                        <Route path = {"/home"} element = {<ColumnComponentContainer admin = {true} tagPrefix={"home1"}/>}></Route>
                        <Route path = {"/productDashboard"} element = {<ColumnComponentContainer_1Hardcoded admin = {true} tagPrefix={"productDashboard1"} harcodedElement={<div tgg = "Doesn't matter"></div>}/>}></Route>
                        <Route path = {"/productPage"} element = {<ColumnComponentContainer_1Hardcoded admin = {true} tagPrefix={"productPage1"} harcodedElement={<div tgg="Doesn't matter"></div>}/>}></Route>


                        <Route path = {"/admin"} element = {<AdminAdmin/>}></Route>



                    </Routes>
                    </>
            );

}