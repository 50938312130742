import React, { useEffect, useRef, useState } from "react";
import "./ObjectPresenterStyle.css";


export default function ObjectPresenter({inputElements, registerHandler}){


 
    let currentElementIndex = useRef(0);

    let animating = useRef(false);

    let textElementsRef = useRef([]);
    let imageElementsRef = useRef([]);
    let backgroundElementsRef = useRef({elements:{}, sortedList:[]});

    let textContainerRef = useRef(null);
    let backgroundContainerRef = useRef(null);

    useEffect(()=>{
        displayElement(currentElementIndex.current);

        registerHandler("onWheel",onWheelHandler);
        ajustFontSize();
    },[]);
   



    let generateTextElements = ()=>{
        let result = [];
        textElementsRef.current = {elements:{}, sortedList:[]};


        for(let index in inputElements)
        {
            let key = new String(index);
            let inputElement = inputElements[index];
            if(inputElement)
            {
                let inputElementTitle = inputElement.title;
                let inputElementSubtitle = inputElement.subtitle;

                let elementRef = {identifier:index, element:null };
                textElementsRef.current.elements[key] = elementRef;

                result.push(<div ref={(r)=>{
                    if(r){
                        elementRef.element = r;
                        if(!textElementsRef.current.sortedList.includes(elementRef))
                        {
                            textElementsRef.current.sortedList.push(elementRef);
                            textElementsRef.current.sortedList.sort((a,b)=>a.identifier-b.identifier);
                        }
                    }
                }}
                    className="textElement"
                    >
                        <div className="titleElement">{inputElementTitle}</div>
                        <div className="subtitleElement">{inputElementSubtitle}</div></div>);
            }
        }
        return result;



    }
    let generateImageElements = ()=>{
        let result = [];
        imageElementsRef.current = {elements:{}, sortedList:[]};


        for(let index in inputElements)
        {
            let key = new String(index);
            let inputElement = inputElements[index];
            if(inputElement)
            {
                let imageUrl = inputElement.image;
 
                let elementRef = {identifier:index, element:null };
                imageElementsRef.current.elements[key] = elementRef;

                result.push(<div ref={(r)=>{
                    if(r){
                        elementRef.element = r;
                        if(!imageElementsRef.current.sortedList.includes(elementRef))
                        {
                            imageElementsRef.current.sortedList.push(elementRef);
                            imageElementsRef.current.sortedList.sort((a,b)=>a.identifier-b.identifier);
                        }
                    }
                }}
                    className="imageElement"
                    style={{backgroundImage:"url("+imageUrl+")"}}
                    ></div>);
            }
        }
        return result;



    }
    let generateBackgroundElements = ()=>{
        let result = [];
        backgroundElementsRef.current = {elements:{}, sortedList:[]};


        for(let index in inputElements)
        {
            let key = new String(index);
            let inputElement = inputElements[index];
            if(inputElement)
            {
                let inputElementBackground = inputElement.background;

                let elementRef = {identifier:index, element:null };
                backgroundElementsRef.current.elements[key] = elementRef;

                result.push(<div ref={(r)=>{
                    if(r){
                        elementRef.element = r;
                        if(!backgroundElementsRef.current.sortedList.includes(elementRef))
                        {
                            backgroundElementsRef.current.sortedList.push(elementRef);
                            backgroundElementsRef.current.sortedList.sort((a,b)=>a.identifier-b.identifier);
                        }
                    }
                }}
                    className="backgroundElement"
                    style={(inputElementBackground?{background:inputElementBackground}:{})}></div>);
            }
        }
        return result;



    }

    const displayElement = (targetIndex)=>{
        displayTextElement(targetIndex);
        displayImageElement(targetIndex);
        displayBackgroundElement(targetIndex);

    }
    const displayTextElement = (targetIndex)=>{
        for(let index in textElementsRef.current.sortedList)
        {
          let element = textElementsRef.current.sortedList[index];
          if(element && element.element){
              element.element.classList.remove("exitBefore");
              element.element.classList.remove("exitAfter");
              element.element.classList.remove("onStage");
  
              element.element.classList.add((index<targetIndex?"exitAfter":(index>targetIndex?"exitBefore":"onStage")));
          }
  
        }
    }
    const displayImageElement = (targetIndex)=>{
        for(let index in imageElementsRef.current.sortedList)
        {
          let element = imageElementsRef.current.sortedList[index];
          if(element && element.element){
              element.element.classList.remove("exitBefore");
              element.element.classList.remove("exitAfter");
              element.element.classList.remove("onStage");
  
              element.element.classList.add((index<targetIndex?"exitAfter":(index>targetIndex?"exitBefore":"onStage")));
          }
  
        }
    }
    const displayBackgroundElement = (targetIndex)=>{
        for(let index in backgroundElementsRef.current.sortedList)
        {
          let element = backgroundElementsRef.current.sortedList[index];
          if(element && element.element){
              element.element.classList.remove("exitBefore");
              element.element.classList.remove("exitAfter");
              element.element.classList.remove("onStage");
  
              element.element.classList.add((index<targetIndex?"exitAfter":(index>targetIndex?"exitBefore":"onStage")));
          }
  
        }
    }


    const onWheelHandler  = (e)=>{

        if(backgroundElementsRef && backgroundElementsRef.current.sortedList)
        {
              
                    if(!animating.current)
                    {
 
                        if(e.deltaY<0)
                        {
                             if(currentElementIndex.current>0){
                                //setCurentElementIndex(curentElementIndex-1);
                                currentElementIndex.current--;                
                                displayElement(currentElementIndex.current);
                                animating.current =true;
                                setTimeout(()=> {animating.current = false}, 1500);

                                return true;

                            }
                        }
                        if(e.deltaY>0)
                        {
                             if(currentElementIndex.current<backgroundElementsRef.current.sortedList.length-1){
                                 currentElementIndex.current++;
                                displayElement(currentElementIndex.current);
                                animating.current =true;
                                setTimeout(()=> {animating.current = false}, 1500);

                                return true;

                            }
                        }
                        return false;

                    }
                    else{
                        return true;
                    }

                
            

        }
    }


    const ajustFontSize = ()=>{

        for (let index in textElementsRef.current.sortedList)
        {
            let fontSize = 1;

            let element = textElementsRef.current.sortedList[index];
            if(element)
            {
                let domElement = element.element;
                if(domElement){
 

                    let titleElement = null;
                    let subtitleElement = null;
                    let idx = 0;
                   while(idx< domElement.children.length)
                    {
 
                         if(domElement.children[idx].classList.contains("titleElement"))
                        {
                            titleElement = domElement.children[idx];
                        }
                        if(domElement.children[idx].classList.contains("subtitleElement"))
                        {
                            subtitleElement = domElement.children[idx];
                        }
                        idx++;
                    }
                    while(domElement.clientHeight>=domElement.scrollHeight && domElement.clientWidth>=domElement.scrollWidth)
                    {
                        fontSize++;
                         if(titleElement)
                        {
                            titleElement.style.fontSize = fontSize+"px";

                        }
                        if(subtitleElement)
                        {
                            subtitleElement.style.fontSize = fontSize*0.4+"px";

                        }
                    
                        
                    }
                    while(domElement.clientHeight<domElement.scrollHeight || domElement.clientWidth<domElement.scrollWidth)
                    {
                        fontSize--;
                         if(titleElement)
                        {
                            titleElement.style.fontSize = fontSize+"px";

                        }
                        if(subtitleElement)
                        {
                            subtitleElement.style.fontSize = fontSize*0.4+"px";

                        }
                   
                    }
                    console.log("ultimate scrol", domElement.clientHeight, domElement.scrollHeight)
                }
                

            }
        }
    }

    return(
        <div className="objectPresenterMainWrapper">
            <div className="objectPresenterMain">
                <div className="backgroundContainer">
                    <div ref = {backgroundContainerRef} className="inputBackgroundContainer">
                        {generateBackgroundElements()}
                    </div>
                    <div className="patternContainer flex flexRow flexNoWrap flexJustifyStart">
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>
                        <div className="patternElement"></div>

                    </div>
                </div>
                <div className="contentContainer">

                    <div ref = {textContainerRef} className="textContainer">
                        {generateTextElements()}
                    </div>
                    <div className="imageContainer">
                        {generateImageElements()}
                     </div>
                </div>
            </div>
        </div>
    );
}