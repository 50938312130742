import React, { useEffect, useRef, useState } from "react";
import "./GenericItemCarouselStyle.css";

export default function GenericItemCarousel({items})
{

    const mainRef = useRef(null);
    const itemContainerRef = useRef(null);
    let [itemsContainerTranslationControl, setItemsContainerTranslationControl] = useState({translateX:0, firstElementIndex:0});
    const lastTouchX = useRef(0);
    const lastTouchY = useRef(0);
    const lastDeltaX = useRef(0);

    useEffect(()=>{
       

       
        if(mainRef.current)
        {
            let resizeObserver = new ResizeObserver((targetMaybe)=>{
                refreshItemListPosition();
            });
            resizeObserver.observe(mainRef.current);
            return ()=>{
                
                    resizeObserver.disconnect();
                
            }
        }

        
        
    },[items]);

    useEffect(()=>{
       

       
        if(itemContainerRef.current)
        {
            let hand = (event)=>{
                //event.preventDefault();
                handleTouchMove(event.touches[0], itemsContainerTranslationControl);

            };
            itemContainerRef.current.addEventListener("touchmove", hand, {passive:false});
             return(()=>{
                if(itemContainerRef.current)
                {
                    itemContainerRef.current.removeEventListener("touchmove", hand);

                }
            });
        }
    

        
        
    },[itemsContainerTranslationControl]);

    const displayPrevious =()=>{
        if(itemsContainerTranslationControl)
        {
            if(itemsContainerTranslationControl.translateX>=0)
            {
                return "none";
            }
        }
        return "flex";
    }
    const displayNext =()=>{
        if(mainRef.current && itemContainerRef.current && itemsContainerTranslationControl)
        {
            let mainWidth = mainRef.current.getBoundingClientRect().width;
            let iContainerWidth = itemContainerRef.current.getBoundingClientRect().width;

            console.log("mainWidth",mainWidth);
            console.log("iContainerWidth",iContainerWidth + itemsContainerTranslationControl.translateX);

            if((iContainerWidth + itemsContainerTranslationControl.translateX)<=(mainWidth+10))
            {
                return "none";
            }
        }
        return "flex";
    }
    const generateContent = () => {
        let content =[];
        for(let index in items)
        {
            content.push(items[index].domElement);
         }
        return content;
    }

    const adjustTranslation =(tV)=>{

        try{
            let mC = mainRef.current.getBoundingClientRect();
            let iC = itemContainerRef.current.getBoundingClientRect();
            if(tV > mC.x)
            {
                console.log("aaabbb");
                return 0;
            }
            if(iC.width+tV < mC.width)
            {
                console.log("aaabbb2");

                let difTV = mC.width-(iC.width+tV); //Math.min(mC.right-(iC.width+tV), Math.abs(tV));
                return Math.min(tV+  difTV, 0);
            }
        }catch(e){}
        return tV;

    }
    const next = (control)=>{


        let index = control.firstElementIndex;
        let translate = control.translateX;
        console.log("ok");
        if(index <items.length-1)
        {
            console.log(index, translate);
            index++;
            translate =   (itemContainerRef.current.getBoundingClientRect().x-items[index].ref.getBoundingClientRect().x);
            console.log(index, translate);

            let newTranslate = adjustTranslation(translate);
            if(newTranslate!==translate )
            {
                if(newTranslate< control.translateX)
                {
                    setItemsContainerTranslationControl ({firstElementIndex:index, translateX:newTranslate});

                }
                else{
                 setItemsContainerTranslationControl ({firstElementIndex:index-1, translateX:newTranslate});
                }
     


            }
            else{
                setItemsContainerTranslationControl ({firstElementIndex:index, translateX:translate});

            }


        }

    }
    const refreshItemListPosition = ()=>{


        //acces the current value of control
        setItemsContainerTranslationControl((control)=>{
            try{
                let index = control.firstElementIndex;
                let translate = control.translateX;
            
                    translate =   (itemContainerRef.current.getBoundingClientRect().x-items[index].ref.getBoundingClientRect().x);
                    console.log(index, translate);

                    let newTranslate = adjustTranslation(translate);
                    if(newTranslate!==translate )
                    {
                        if(newTranslate< control.translateX)
                        {
                            return ({firstElementIndex:index, translateX:newTranslate});

                        }
                        else{
                            //aici era index -1 dar de ce?
                            // a cumva daca nu se face trasnslatie decoc nu mai cres cindexu
                            // dfar ar trebui sa fie un delta daca abs(newTranslate - translate)<15 atunci pun -1
                        return ({firstElementIndex:index, translateX:newTranslate});
                        }
            


                    }
                    else{
                        return ({firstElementIndex:index, translateX:translate});

                    }
                }catch(e){return control};
        });


        

    }
    const previous = (control)=>{
        let index = control.firstElementIndex;
        let translate = control.translateX;
        console.log("ok");
        if(index > 0)
        {
            console.log(index, translate);
            index--;
          //  translate = translate - (items[index].ref.getBoundingClientRect().x-mainRef.current.getBoundingClientRect().x);
          //using the following compute calculus will no longer need the translate value to be persisted  between states(=f(index), not = f(index, prevTranslateValue))
          
          translate =   (itemContainerRef.current.getBoundingClientRect().x-items[index].ref.getBoundingClientRect().x);
          console.log(index, translate);

            setItemsContainerTranslationControl ({firstElementIndex:index, translateX:translate});
        }
    }

    const handleTouchStart = (coord)=>{
        console.log("TS");

        lastTouchX.current =coord.clientX;
        lastTouchY.current = coord.clientY;
        itemContainerRef.current.style.transition = "0s";

    }
    const handleTouchMove = (coord,control)=>{
        console.log("TM");
         let deltaX = (lastTouchX.current - coord.clientX) ;//*1.1;
         let deltaY = (lastTouchY.current - coord.clientY) ;

         lastDeltaX.current = deltaX;
        lastTouchX.current = coord.clientX;
        lastTouchY.current = coord.clientY;
     if(Math.abs(deltaX)>=3*Math.abs(deltaY))
        {
            let translate = control.translateX-deltaX;
            translate = adjustTranslation(translate);
            setItemsContainerTranslationControl({firstElementIndex:control.firstElementIndex, translateX:translate});
        }

    }
    const handleTouchEnd = (coord)=>{
        console.log("TE");

        itemContainerRef.current.style.transition = "transform 1s";
        console.log("iutems", items);
        let index = 0;
        while(items[index].ref.getBoundingClientRect().x< mainRef.current.getBoundingClientRect().x)
        {
            index++;
        }
        if(lastDeltaX.current<0)
        {
            if(index>0)
            {index--;}
        }
        setItemsContainerTranslationControl({translateX:itemsContainerTranslationControl.translateX, firstElementIndex:index});
        refreshItemListPosition();

    }
    return(
        <div ref={mainRef} className="genericItemCarouselMain"
        
        >
            <div ref = {itemContainerRef } className="itemsContainer"  style = {{transform:"translateX("+itemsContainerTranslationControl.translateX+"px)"}}
            
            onTouchStart={(event)=>{handleTouchStart(event.touches[0])}}
            onTouchEnd={(event)=>{handleTouchEnd()}}>
                {generateContent()}
            </div>
            <div style = {{display:displayPrevious()}} onClick = {()=>previous(itemsContainerTranslationControl)} className="previous flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter"><div className="previousArrow"></div></div>

            <div  style = {{display:displayNext()}} onClick = {()=>next(itemsContainerTranslationControl)} className="next flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter"><div className="nextArrow"></div></div>
        </div>
    );
}