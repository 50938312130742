import React, { useEffect, useRef } from "react";
import "./ScrollableElementStyle.css";


export default function ScrollableElement({registerHandler}){

    let scrollable = useRef(null);
    let fullHeight = useRef(null);
    let currentScroll = useRef(0);
    useEffect(()=>{
        registerHandler("onWheel", (e)=>{
            console.log("scrollable", e.deltaY, scrollable.current.clientHeight, scrollable.current.scrollHeight, fullHeight.current.bottom);
            if(scrollable.current && fullHeight.current)
            {
                //if translated befor id doesn generate overflow///..wtf so this condition no
                //mabie the absolute position of the child has influence , but is needed so ...
                //if(scrollable.current.clientHeight<scrollable.current.scrollHeight)
                {
                    if(e.deltaY>0)
                    {
                        if(fullHeight.current.getBoundingClientRect().bottom>scrollable.current.getBoundingClientRect().bottom+1){
                            console.log("-");
                            currentScroll.current-=e.deltaY;
                            if(currentScroll.current< scrollable.current.clientHeight - fullHeight.current.clientHeight){
                                currentScroll.current = scrollable.current.clientHeight - fullHeight.current.clientHeight ;
                            }
                            fullHeight.current.style.transform = "translateY("+currentScroll.current+"px)";
                            return true;
                        }
                    }
                    if(e.deltaY<0)
                    {
                        console.log("++++");

                        if(fullHeight.current.getBoundingClientRect().top< scrollable.current.getBoundingClientRect().top-1){

                            currentScroll.current-=e.deltaY;
                            if(currentScroll.current>0){currentScroll.current = 0;}
                            fullHeight.current.style.transform = "translateY("+currentScroll.current+"px)";
                            return true;
                        }
                    }
                }

            }
        })
    },[]);

    return (
        <div ref ={scrollable} className="scrollableElementMain">
            <div  ref = {fullHeight} className="extraLarge flex flexColumn flexJustifySpaceBetween">

                <div className="vh100">T1</div>
                <div className="vh100">T2</div>
            </div>
        </div>
    );
}