import { performHttpRequest } from "../api/http/generic";


class UserProvider{
    constructor(){
        this.userDetails = null;
        this.authorized = false;
        this.observers = [];

        this.refresh();
    }
     refresh = async ()=>{
         let rsp = await new Promise((re,rj)=>{
            performHttpRequest("GET", "/user/auth/getUserDetails",null,null,(r,c,e,j)=>{
            this.userDetails = null;
            if(c>=200 && c<=206){
                if(r){
                    this.userDetails = r;
                }
                this.authorized = true;
            }
            else{
                if(c===401 || c === 403)
                {
                    this.authorized = false;
                }
            }
            re(this.userDetails)
            });
        });
            try{
                for(let index in this.observers)
                {
                    try{
                        this.observers[index](rsp);
                    }catch(e){
                        console.log(e);
                    }
                }
            }catch(e){
                console.log(e);
            }
           return rsp;
       
    }
}


export default new UserProvider();