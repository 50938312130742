import React, { useEffect , useState} from "react";
import { performHttpRequest } from "../../api/http/generic";
import "./AdminTableStyle.css"
import { useNavigate } from "react-router-dom";
export default function AdminTable({parentId, signal}){

    let [categoryList, setCategoryList] = useState([]);
    let redirectTo  = useNavigate();
    useEffect(()=>{
        performHttpRequest("GET","/user/admin/adminUsers",null,null,(r,c,e,j)=>{
            setCategoryList(r);
        })
    },[parentId, signal]);
//on click redirect to category page /admin/category/categoryId



const removeAdmin=(id) =>{

    performHttpRequest("POST", "/user/admin/removeAdmin", null, JSON.stringify({id:id}),(r,c,e,j)=>{
        performHttpRequest("GET","/user/admin/adminUsers",null,null,(r,c,e,j)=>{
            setCategoryList(r);
        })
});
}
const generateCategoryTable = ()=>{
    let result = [];

    for(let index in categoryList)
    {
        result.push(
            <div className="categoryTableItem">
                <div className="   flex flexRow flexJustifySpaceBetween flexNoWrap">
                    <div className="boldText">{categoryList[index]?.fullName}</div>
                    <div className="flex  flexAlignItemsStart flexAlignContentStart ">
                        <div className="boldText">{categoryList[index]?.email}</div>
                     </div>
                     <div className="deleteAdmin" onClick={()=>removeAdmin(categoryList[index]?.id)}>Elimina</div>
                     
                </div>
               
                <div/>
               
            </div>
        );
    }
    return result;
}
    return (
        <div className="categoryTableMain">
         
            <div className="categoryTableTable flex flexColumn flexJustifyStart flexAlignContentCenter flexAlignItemsCenter">
                {(categoryList && categoryList.length && categoryList.length>0)?generateCategoryTable():"Nu exista date de afisat"}
            </div>
        </div>
    );
}