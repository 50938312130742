import * as httpClient from "../../connectors/http/http.js";
import HOST from "../../configuration/backend/host.js";
import PROXY from "../../configuration/proxy/proxy.js";
function performHttpRequest(requestType, path, headers, body, callback)
{
    if(requestType)
    {
        if(typeof(requestType) === typeof("sample"))
        {
            let mappedHeaders={
                "Content-Type": "application/json"
            };

            if(headers)
            for(let key in headers)
            {
                if(headers[key]=== null || headers[key]=== undefined) 
                {
                    delete mappedHeaders[key];
                }
                else{
                    mappedHeaders[key]=headers[key];

                }
            }
            let request = null;
            switch(requestType.toUpperCase())
            {

                case "GET":
                     request = new Request(HOST.schema + "//" + HOST.name  + HOST.port + PROXY.backend + path,
                    {
                        method:"GET"
                         
                    
                    });
                    break;
                case "POST":
                      request = new Request(HOST.schema + "//" + HOST.name  + HOST.port + PROXY.backend + path,
                    {
                        method:"POST",
                        headers:mappedHeaders,
                        body: body
                    
                    });
                    break;
                case "PUT":
                    request = new Request(HOST.schema + "//" + HOST.name  + HOST.port + PROXY.backend + path,
                    {
                        method:"PUT",
                        headers:mappedHeaders,
                        body: body
                    
                    });
                    break;
                case "PATCH":
                    request = new Request(HOST.schema + "//" + HOST.name  + HOST.port + PROXY.backend + path,
                    {
                        method:"PATCH",
                        headers:mappedHeaders,
                        body: body
                    
                    });
                    break;
                case "DELETE":
                    request = new Request(HOST.schema + "//" + HOST.name  + HOST.port + PROXY.backend + path,
                    {
                        method:"DELETE",
                        headers:mappedHeaders,
                        body: body,
                    
                    });
                    break;
            }
            if(request)
            {
                httpClient.performRequest(request, callback);
            }
        }
    }
}


export {performHttpRequest};