import React, { useEffect, useState } from "react";
import GenericItemCarousel from "../../itemContainer/layout/carousel/GenericItemCarousel";
import "../../itemContainer/ContainerBootstrapStyle.css";
import P1T1S1 from "../item/P1T1S1";
import { clearAllByPrefix, loadText, storeText } from "../../api/ObjectStoreApi";
import "../GenericStyle.css"
export default function SimpleFatItemCarousel({tagPrefix, admin}){

    let [itemsMeta, setItemsMeta] = useState({items:[], nextId:1});
    let [title, setTitle] = useState("no title");
    let [subTitle, setSubtitle] = useState("no subtitle");
    let [bC, setBC] = useState("");
    let [fC, setFC] = useState("");

    /*
    {itemId:...,
     itemOrderIndex:...}
        */


    useEffect(()=>{
        setItemsMeta({items:[], nextId:1});
        setTitle("no title");
        setSubtitle("no subtitle");
        setBC("");
        setFC("");
        fetchItemsMeta();
        loadTitle();
        loadSubtitle();
        loadColor();
        loadFC();
    },[tagPrefix, admin]);

    const fetchItemsMeta = ()=>{

        loadText(tagPrefix+".itemsMeta").then((r)=>{
            try{
                let meta = JSON.parse(r);
                if(!meta?.items || !Array.isArray(meta.items))
                {
                    meta.items =[];
                }
                if(!meta.nextId)
                {
                    meta.nextId = meta.items.length+1;
                }
                setItemsMeta(meta);
            }
            catch(e)
            {

            }
        }).catch(e=>{});
    }

    const addNewItem = ()=>{

        // the items meta is up to date at this point beacuse this function is called from inside a lambda function tha is created with each render so the closure of this call will contain the most up to date value
    
        if(itemsMeta && Array.isArray(itemsMeta.items))
        {
            let newItem = {id: itemsMeta.nextId, orderIdex:itemsMeta.items.length};
            let newMeta = JSON.parse(JSON.stringify(itemsMeta));
            newMeta.items.push(newItem);
            newMeta.nextId++;
            storeText(tagPrefix+".itemsMeta", JSON.stringify(newMeta)).then(()=>fetchItemsMeta());

        }
        else{
            storeText(tagPrefix+".itemsMeta", JSON.stringify({items:[{id:1, orderIdex:0}], nextId:2})).then(()=>fetchItemsMeta());

        }
        

    }

    const removeItem  = (listIndex)=>{
        let iMCopy = JSON.parse(JSON.stringify(itemsMeta));
        if(iMCopy)
        {
            if(iMCopy.items)
            {
                if(iMCopy.items.length>listIndex)
                {
                    let toBeRemoved = iMCopy.items[listIndex];
                    iMCopy.items.splice(listIndex,1);
                    if(iMCopy.items.length===0)
                    {
                        iMCopy.nextId = 1;
                    }
                    else{
                    iMCopy.nextId = iMCopy.items.reduce((acc, item)=>Math.max(acc, item.id+1),1);
                    }
                    clearAllByPrefix(tagPrefix+".item."+toBeRemoved.id).then(r=>{}).catch(e=>{});
                    storeText(tagPrefix+".itemsMeta", JSON.stringify(iMCopy)).then(()=>fetchItemsMeta());

                }
            }
        }
    }

    const storeTitle = (titleInput)=>{
        storeText(tagPrefix+".title", titleInput);
    }
    const loadTitle = ()=>{
        loadText(tagPrefix+".title").then((color)=>setTitle(color)).catch((e)=>{});
    }
    const storeSubtitle = (titleInput)=>{
        storeText(tagPrefix+".subtitle", titleInput);
    }
    const loadSubtitle = ()=>{
        loadText(tagPrefix+".subtitle").then((color)=>setSubtitle(color)).catch((e)=>{});
    }
    const storeColor = ()=>{
        storeText(tagPrefix+".C", bC);
    }
    const loadColor = ()=>{
        loadText(tagPrefix+".C").then((color)=>setBC(color)).catch((e)=>{});
    }
    const storeFC = ()=>{
        storeText(tagPrefix+".fC", fC);
    }
    const loadFC = ()=>{
        loadText(tagPrefix+".fC").then((color)=>setFC(color)).catch((e)=>{});
    }
    const generateItemList = ()=>{
        let items = [];

        if(itemsMeta  && Array.isArray(itemsMeta.items))
        for(let i =0; i<itemsMeta?.items.length;i++){
            let itemMeta = itemsMeta.items[i];
            let item = {};
            if(admin)
            {
                item["domElement"] =(
                    <div className="ic_asrto07_big"ref = {(r)=>item.ref = r} style = {{}}>
                        <div style ={{width:"100%", height:"calc(100% - 3rem"}}>
                            <P1T1S1 itemDeletionHandler = {()=>{removeItem(new Number(i))}}tagPrefix = {tagPrefix+".item."+itemMeta.id} admin={admin} imgWidth={800}>{i}</P1T1S1>
                        </div>
                        <div  className = "flex flexRow flexJustifyCenter " style ={{width:"100%", height:"3rem", overflow:"hidden", padding:"0.2rem"}}> 
                            <div onClick = {()=>{removeItem(new Number(i))}} className="close"></div>
                        </div>
                    </div>);
            }
            else{
                item["domElement"] =(
                    <div className="ic_asrto07_big"ref = {(r)=>item.ref = r} style = {{}}>
                        <div style ={{width:"100%", height:"100%"}}>
                            <P1T1S1 itemDeletionHandler = {()=>{removeItem(new Number(i))}}tagPrefix = {tagPrefix+".item."+itemMeta.id} admin={admin} imgWidth={800}>{i}</P1T1S1>
                        </div>
                    
                    </div>);
            }
            items.push(item);    
            
        }
        if(admin)
        {
            let item = {};
            item["domElement"] =(
                <div className="ic_asrto07_big"ref = {(r)=>item.ref = r} style = {{}}>
                    <div className = "flex flexJustifyCenter flexAlignItemsCenter flexAlignContentCenter" style={{width:"3rem", height:"100%"}}>
                    <div className="add" onClick={()=>{addNewItem()}}></div>
                    </div>
                </div>);
            items.push(item);
        }
        return items;
    }
    return (
        <div className="fullParentWidth" style={{padding:"1vh 1vw 1vh 1vw",backgroundColor:(admin?"":bC), color:(admin?"":fC)}}>
            { admin && 
                <div className="fullParentWidth flex flexColumn flexJustifyStart">
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignItemsCenter flexAlignCOntentCenter">
                        <div contentEditable className="contentEditableDiv"
                            onBlur={(event)=>setTitle(event.target.innerText)}>{title}</div>
                        <div className="check" onClick={()=>{storeTitle(title)}}></div>
                    </div>
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignItemsCenter flexAlignCOntentCenter">
                        <div contentEditable className="contentEditableDiv"
                            onBlur={(event)=>setSubtitle(event.target.innerText)}>{subTitle}</div>
                        <div className="check" onClick={()=>{storeSubtitle(subTitle)}}></div>
                    </div>
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter breakWordPretty maxWidthParent" style={{padding:"0.5rem"}}>
                        <div className="breakWordPretty maxWidthParent">Culoare text</div>
                        <input value={fC?fC:"#ffffff"}   type="color" onInput={(event)=>{console.log(event.target.value); setFC(event.target.value);}}/>
                        <div className="check" onClick={storeFC}/>
                    </div>
                    <div className="flex flexRow flexWrap flexJustifyStart flexAlignContentCenter flexAlignItemsCenter breakWordPretty maxWidthParent" style={{padding:"0.5rem"}}>
                        <div className="breakWordPretty maxWidthParent">Culoare fundal</div>
                        <input value={bC?bC:"#ffffff"}   type="color" onInput={(event)=>{console.log(event.target.value); setBC(event.target.value);}}/>
                        <div className="check" onClick={storeColor}/>
                    </div>
                    <GenericItemCarousel items={generateItemList()}/>
                </div>
            }
            { !admin && 
                <div className="fullParentWidth flex flexColumn flexJustifyStart">
                    <div className="breakWordPretty" style={{maxWidth:"100%", fontSize:"1.5rem", fontWeight:"600", padding:"0 0 0.2rem 0"}}>{title}</div>
                    <div className="breakWordPretty" style={{maxWidth:"100%", fontSize:"1rem", fontWeight:"400", padding:"0 0 0.3rem 0"}}>{subTitle}</div>

                   <GenericItemCarousel items={generateItemList()}/>
                </div>
                
            }
        </div>
    );
}