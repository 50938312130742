import React, { useState } from "react";
import FlexGrid from "../itemContainer/layout/grid/FlexGrid";
import GenericItemContainer from "../itemContainer/layout/GenericItemContainer";
import { NOWRAP } from "../itemContainer/layout/grid/GridWrap";
import { START } from "../itemContainer/layout/grid/GridJustify";
import Footer from "../final/footer/Footer";
import SimpleItemCarousel from "../final/itemContainer/SimpleItemCarousel";
import ComponentSelector from "../componentContainer/componentSelector/ComponentSelector";
import Modal from "../modal/Modal";
import ColumnComponentContainer from "../componentContainer/ColumnComponentContainer";
//this =is the builder
// It receives the composenent metadata
// Layout view strategy
// Item container view strategy
// Item view
export default  function GenericComponentsTest({admin}){


    let [is,setIs] = useState(false);
    const generateTestContent = ()=>{
        let result = [];
        for (let i =0; i<50; i++)
        {
            let item = (<div style={{backgroundColor:"beige", minHeight:"100%", minWidth:"100%"}}> Ana are {i} mere</div>);
        result.push(<GenericItemContainer options={{aspectRatio:0.8}} item = {item}/>);
        }
        return result;
        
    }


return (
    <div>
        
       {/* <FlexGrid options = {{flexWrap:NOWRAP, justifyContent:START}} components = {generateTestContent()}/>*/}
       {/*<div onClick = {()=>{setIs(true)}} className="fullParentWidth flex flexRow flexJustifyCenter" style = {{padding:"0.5rem"}}><button> Adauga un nou element aici</button></div>
       <Footer tagPrefix = "footer"/>
       <div className="fullParentWidth flex flexRow flexJustifyCenter" style = {{padding:"0.5rem"}}><button> Sterge elementul de mai sus</button></div>
       <div className="fullParentWidth flex flexRow flexJustifyCenter" style = {{padding:"0.5rem"}}><button> Adauga un nou element aici</button></div>

       <Footer tagPrefix = "footer" admin={true}/>
       <div className="fullParentWidth flex flexRow flexJustifyCenter" style = {{padding:"0.5rem"}}><button> Sterge elementul de mai sus</button></div>
       <div className="fullParentWidth flex flexRow flexJustifyCenter" style = {{padding:"0.5rem"}}><button> Adauga un nou element aici</button></div>

       <SimpleItemCarousel tagPrefix={"t_c"} admin={true}/>
       <div className="fullParentWidth flex flexRow flexJustifyCenter" style = {{padding:"0.5rem"}}><button> Sterge elementul de mai sus</button></div>
       <div className="fullParentWidth flex flexRow flexJustifyCenter" style = {{padding:"0.5rem"}}><button> Adauga un nou element aici</button></div>

       <Modal closeFunction = {()=>{setIs(false)} } isOpen = {is} element={<ComponentSelector/>}/>
*/}
       <ColumnComponentContainer tagPrefix="cc4" admin={admin}/>
    </div>
);}
