import React, { useEffect, useRef, useState } from 'react';
import { performHttpRequest } from '../../api/http/generic';
import "./ProductImageAdminStyle.css"
import HOST from '../../configuration/backend/host';
import PROXY from '../../configuration/proxy/proxy';
export default function ProductImageAdmin ({productId}) {
  let [imageFile, setImageFile] = useState(null);
  let [jsonFile, setJsonFile] = useState(null);

  
  let [imageList, setImageList]  = useState([]);

  let [selectedImageId, setSelectedImageId] =useState(null);
  let [repositioning, setRepositioning] = useState(false);
     
    useEffect(()=>{if(productId)fetchImageList()},[productId]);
    useEffect(()=>{
      let result = housekeepImageIndexList(imageList);
      if(result.modified)
      {
        saveImageIndex(result.newList);
      }
    },[imageList]);

  const fetchImageList = ()=>{
    setSelectedImageId(null);
    setRepositioning(false);
    performHttpRequest("GET","/generic/product/"+productId+"?attributes=id&references=image;id-index",null, null,(r,c,e,j)=>{
        if(c>=200 && c<=206)
        {
            if(r){
                let finalImageList = [];
                let images = r?.Images;
                if(Array.isArray(images))
                {
                    for(let index in images)
                    {
                        finalImageList.push( {id:images[index]?.id, index: images[index]?.ProductImage?.index});
                    }
                }
                finalImageList.sort((a,b)=>{return a.index-b.index});
                setImageList(finalImageList);
            }
             
        }
    });
  }
  const housekeepImageIndexList = (inputList)=>{
      
        let iList = JSON.parse(JSON.stringify(inputList));
        let changes = false;
        for(let index in iList)
        {
            if(Number.isNaN(iList[index].index) || iList[index].index !== Number.parseInt(index))
            {
                iList[index].index = Number.parseInt(index);
                changes = true;
            }
        }
        return {newList: iList, modified: changes };
        
      
  }
  const saveImageIndex = (iList)=>{
    let body = {productId:productId, indexList:[]};
    for(let index in iList)
    {
        body.indexList.push({imageId:iList[index].id, index: iList[index].index});
    }
    performHttpRequest("POST","/product/imageIndex",null,JSON.stringify(body),()=>{fetchImageList()});

  }
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleJsonChange = (e) => {
    setJsonFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
 
    if (!imageFile ) {
      console.error('Please select both an image and a JSON file.');
      return;
    }

    let index = (imageList && Array.isArray(imageList) && imageList.length>0 && imageList[imageList.length-1])?imageList[imageList.length-1].index+1:0;
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('jsonObject', JSON.stringify({productId:productId, index:index}));
     try {
        performHttpRequest("POST","/product/image",{["Content-Type"]:null},formData,()=>{fetchImageList()});
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };
  const deleteImage = (imageId)=>{
    performHttpRequest("DELETE", "/generic/image/"+imageId,null, null, ()=>{fetchImageList()});
  };

  const changeIndex=(index)=>{
    
     if(selectedImageId!==null && selectedImageId!==undefined && index !== null && index!== undefined)
    {
      
        try{
          let selectedIndex = imageList.find((obj)=>obj.id === selectedImageId).index;
          if(selectedIndex!==index)
          {
 
            
              let old = JSON.parse(JSON.stringify(imageList));
              if(selectedIndex<index)
              {
                for(let ind = index ; ind>selectedIndex;ind--)
                {
                  old[ind].index--;
                }
              }
              else{
                for(let ind=index ; ind<selectedIndex; ind++)
                {
                  old[ind].index++;
                }
              }
              old[selectedIndex].index = index;
              old.sort((a,b)=>{return a.index-b.index});

              saveImageIndex(old);

 
             
          }
      }catch(e){
        console.log(e);
      }
    }

  
  
  }

  const generateImageList = ()=>{
    let result = [];

    for(let index in imageList)
    {
        result.push(
          <div    className={"productAdminImagePreviewWrapper "+(repositioning?"repositioning":"")}>
            <img 
             /*onDragStart={(e)=>{console.log("dragStart", index, e); selectedIndex =Number.parseInt(index)}}
             onDragEnd = {(e)=>{e.preventDefault();console.log("dragEnd", index, e); selectedIndex=null;saveImageIndex()}}
             onDragEnter={(e)=>{e.preventDefault();console.log("dragEnter", index, e);onDragEnter(Number.parseInt(index));}}   
             draggable="false" */
             onClick = {()=>{(repositioning?changeIndex(imageList[index].index):setSelectedImageId(imageList[index]?.id))}} 
             src={HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/image/"+imageList[index]?.id+"?width=250"} className='productImagePreview'/>
             
              
            
            
            
          </div>
        );
    }
    return result;
  }

  return (
    <div className='productImageAdminMain flex flexColumn flexJustifyStart flexAlignContentStart flexAlignItemsStart'>
       
        
        <div className='productImageList  flex flexRow flexWrap flexJustifySpaceStart flexAlignContentCenter flexAlignItemsCenter'>
            {generateImageList()}
             
        </div>
        <div className="productImageAdminImageView flex flexColumn flexJustifyEnd  flexAlignContentCenter flexAlignItemsCenter ">
          <div className="imageArea">
            {selectedImageId!==undefined && selectedImageId!==null &&<img src = {HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/image/"+selectedImageId+"?width=750"}/>}
          </div>
          <div className="actionArea flex flexRow flexJustifyCenter">
          {selectedImageId!==undefined && selectedImageId!==null &&<button onClick = {()=>{deleteImage(selectedImageId)}}>Sterge</button>}
          {selectedImageId!==undefined && selectedImageId!==null &&<button onClick = {()=>{setRepositioning(!repositioning)}}>Repozitioneaza</button>}

          </div>

        </div>
         
        <div>
            <label htmlFor="image">Incarca o imagine noua: &nbsp;</label>
            <input type="file" id="image" accept="image/*" onChange={handleImageChange} />
        </div>

        
        <div onClick = {()=>{handleSubmit()}} className="adminSpecificCategoryAddCategory flex flexRow flexNoWrap flexAlignContentCenter flexAlignItemsCenter">
            <svg  className="adminSpecificCategoryAddCategoryIcon" height="256.000000pt" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 256.000000 256.000000" width="256.000000pt" xmlns="http://www.w3.org/2000/svg"><g fill="#000000" stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"><path d="M2016 2465 c-22 -8 -53 -24 -70 -36 -35 -25 -175 -171 -345 -359 -320 -352 -690 -719 -1088 -1078 l-190 -170 -41 -105 c-66 -169 -203 -587 -200 -610 2 -13 11 -23 24 -25 21 -3 316 96 559 188 162 62 138 42 400 335 319 356 648 680 1090 1071 283 252 325 307 325 428 -1 68 -31 115 -156 237 -91 89 -128 119 -159 128 -53 14 -101 13 -149 -4z m117 -159 c46 -19 173 -154 181 -193 4 -17 2 -50 -4 -72 -12 -47 -56 -90 -420 -422 -390 -355 -503 -467 -1021 -1009 l-187 -195 -78 -29 c-44 -16 -84 -31 -91 -33 -6 -3 -14 6 -18 18 -11 32 -81 105 -116 119 -36 15 -35 23 12 135 28 67 38 79 251 280 351 332 706 689 954 960 331 362 392 423 439 440 51 18 59 18 98 1z"/></g></svg>
            <div className="lightText">incarca imaginea</div>
        </div>
                
           
          
    </div>
  );
};
 