import React, { useEffect, useRef, useState } from "react";
import "./ProductTopbarStyle.css";
import UserProvider from "../../userProvider/UserProvider";
import { useNavigate } from "react-router-dom";


export default function ProductTopbar(){

    let minWrapLevel = 1;
    let maxWrapLevel= 10;
    let [userDetails, setuserDetails] = useState(null);
    let redirectTo = useNavigate();
    let topBar = useRef(null);
    let [wrapLevel, setWrapLevel] = useState(1);

    let [displayDropdown, setDisplayDropdown] = useState(false);
    useEffect(()=>{

        let observer = (newUserDetails)=>{
            setuserDetails(newUserDetails);
        }
        setuserDetails(UserProvider.userDetails);
        UserProvider.refresh().then(()=>{setuserDetails(UserProvider.userDetails)});
        UserProvider.observers.push(observer);


        let resizeHandler = ()=>{
            setWrapLevel(-1);
            

        }
        let rsObs = new ResizeObserver(resizeHandler);
        if(topBar.current)
        {
            rsObs.observe(topBar.current);
        }
        return ()=>{
            UserProvider.observers = UserProvider.observers.filter((el)=>!(el===observer));
            if(topBar.current)
            {
                rsObs.unobserve(topBar.current);
            }
        }
    },[]);
    useEffect(()=>{
        console.log("tr", wrapLevel);
       ajustWrapLevel(wrapLevel);
    },[wrapLevel]);

    const ajustWrapLevel = (wL)=>{
        console.log(isOverflow());
        console.log('trigered', wL, isOverflow());
        if(isOverflow())
        {
            if(wL<=maxWrapLevel)
            {
                setWrapLevel(wL+1);
            }
        }else{
            if(wL<0)
            {
                setWrapLevel(minWrapLevel);
            }
            //at ersize reset wraplevel witch will retriger this
        }
    }


    const isOverflow = ()=>{
    
        if(topBar.current)
        {
            return topBar.current.clientWidth<topBar.current.scrollWidth || topBar.current.clientHeight<topBar.current.scrollHeight;
        }

    }
    const generateUserProfile  = (fullName)=>{

        if(userDetails && userDetails.googleData)
        {
            let gD = JSON.parse(userDetails.googleData);
            if(gD.picture)
            {
                if(fullName)
                {
                    return [<div className="user" style = {{backgroundImage:'url('+gD.picture+')'}}/>, 
                   (wrapLevel<2 || fullName) && <div className="profileInfo"> {(gD&& gD.given_name)?(gD.given_name+((gD.family_name)?" "+gD.family_name:"")):"Profil"}</div>]
       
                }
                return [<div className="user" style = {{backgroundImage:'url('+gD.picture+')'}}/>, 
                (wrapLevel<2 || fullName ) && <div className="profileInfo"> {(gD&& gD.given_name)?(gD.given_name.charAt(0)+"."+((gD.family_name)?gD.family_name.charAt(0)+".":"")):"Profil"}</div>]
           
        }
        }
        return [<div className="user"/>, (wrapLevel<2 || fullName) && <div className="profileInfo">Profil</div>]
    }
    return(
        <div className="productTopbarMainSecondWrapper">
            <div ref={topBar} className="productTopbarMainWrapper">
                <div className="productTopbarMain flex flexRow flexNoWrap flexJustifySpaceBetween">
                    <div className="productTopbarLogoWrapper  flex flexRow flexNoWrap flexJustifyCenter flexAlignItemsEnd flexAlignContentEnd">
                        <div className="productTopbarLogox"/>
                        {wrapLevel <10 && <div className="logoText">
                             
                        </div>}
                    </div>
                
                    
                    <div className="productTopbarActions flex flexRow flexNoWrap flexJustifySpaceEveny flexAlignContentCenter flexAlignItemsCenter">
                        
                        {wrapLevel<9 && <div className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/product")}>
                            <div className="product" onClick={()=>{redirectTo("/product")}}/>
                            {wrapLevel<5&&<div>Produse</div>}
                        </div>}

                        {wrapLevel<8 && <div className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/")}>
                            <div className="home" onClick={()=>{redirectTo("/")}}/>
                            {wrapLevel<4&&<div>Acasa</div>}
                        </div>}
                        

                        {wrapLevel<7 && <div style={{display:"none"}}className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/wishlist")}>
                            <div className="favourites"/>
                            {wrapLevel<3&&<div> </div>}
                        </div>}

                        
                        {wrapLevel<6 && <div className="actionWrapper flex flexRow flexJustifyCenter flexAlignContentCenter flexAlignItemsCenter" onClick={()=>redirectTo("/profile")}>
                            {generateUserProfile()}
                        </div>}

                        <div onClick = {()=>setDisplayDropdown(!displayDropdown)} className="meniu"/>

                        
                    </div>
                

                </div>
            
            </div>
            <div className={"sidebar " + (displayDropdown?"visible":"hidden") }>
                            <div className=" closeWrapper fullParentWidth flex flexRow flexNoWrap flexJustifyEnd">
                                <div className="close coverBackground" onClick={()=>setDisplayDropdown(false)}/>
                            </div>
                            <div className="body">
                            <div onClick = {()=>{redirectTo("/product"); setDisplayDropdown(false);}}className="item flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlingContentEnd" >
                                    <div> Produse </div>
                                    <div className="product"/>

                                </div>
                                <div onClick = {()=>{redirectTo("/");setDisplayDropdown(false);}}className="item flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlingContentEnd" >
                                    <div> Acasa </div>
                                    <div className="home"/>

                                </div>
                                <div onClick = {()=>{redirectTo("/wishlist");setDisplayDropdown(false);}}className="item  flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlignItemsEnd" >
                                    <div> Favorite </div>
                                    <div className="favourites"/>

                                </div>

                                 <div onClick = {()=>{redirectTo("/profile");setDisplayDropdown(false);}}className="item flex flexRow flexNoWrap flexJustifySpaceBetween flexAlignItemsEnd flexAlignItemsEnd" >
                                    {generateUserProfile(true)&& Array.isArray(generateUserProfile(true)) && generateUserProfile(true).reverse()}

                                </div>
                            </div>
                        </div>
        </div>
    );
}