import React, { useEffect , useState} from "react";
import { performHttpRequest } from "../../api/http/generic";
import "./../category/CategoryTableStyle.css"
import { useNavigate } from "react-router-dom";
export default function ProductTable({categoryId, signal}){

    let [productList, setProductList] = useState([]);
    let redirectTo  = useNavigate();
    useEffect(()=>{
       fetchProducts();
    },[categoryId, signal]);

    const fetchProducts = ()=>{
        if(categoryId)
        {
            performHttpRequest("GET","/generic/category/"+categoryId+"?attributes=id&references=product;id;title;subtitle-.", null, null, (r,c,e,j)=>{
                if(c>=200 && c<=206)
                {
                    if(r && r.Products)
                    {
                        setProductList(r.Products);
                    }
                }
            });
        }
        else{
            performHttpRequest("GET","/product/noCategory?attributes=id,title,subtitle", null, null, (r,c,e,j)=>{
                if(c>=200 && c<=206)
                {
                    if(r )
                    {
                        setProductList(r);
                    }
                }
            });// to be reoplacedby no category products
        }
        
    }
//on click redirect to category page /admin/category/categoryId

const generateCategoryTable = ()=>{
    let result = [];

    for(let index in productList)
    {
        result.push(
            <div  onClick = {()=>redirectTo("/admin/product/"+productList[index]?.id)}className="categoryTableItem">
                <div className="   flex flexRow flexJustifySpaceBetween flexNoWrap">
                    <div className="boldText">{productList[index]?.title}</div>
                    <div className="flex  flexAlignItemsStart flexAlignContentStart ">
                        <div className="mediumText">{productList[index]?.subtitle}</div>
                     </div>
                     
                     
                </div>
               
                <div/>
               
            </div>
        );
    }
    return result;
}
    return (
        <div className="categoryTableMain">
         
            <div className="categoryTableTable flex flexColumn flexJustifyStart flexAlignContentCenter flexAlignItemsCenter">
                {(productList && productList.length && productList.length>0)?generateCategoryTable():"Nu exista date de afisat"}
            </div>
        </div>
    );
}