import React, { useState } from "react";
  import "./AdminAdminStyle.css";
import { useNavigate } from "react-router-dom";
import AdminAdministrator from "./AdminAdministrator";

export default function AdminAdmin(){

    let [signal, setSignal] = useState(false);
    let [showFixedSidebar, setShowFixedSidebar]= useState(false);
    let redirect = useNavigate();
    return(
        <div className="categoryAdminMainWrapper">
            <div className="flex flexRow flexJustifySpaceBetween"> 
                <div className="locationInfo lightText">Gestiune administratori</div>
                <div   className = "categoryAdminFixedSidebarActivation" onClick= {()=>setShowFixedSidebar(!showFixedSidebar)}>
                <svg fill="#000000" className="categoryAdminFixedSidebarActivationIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M21,1H3A3,3,0,0,0,3,7H21a3,3,0,0,0,0-6Zm0,4H3A1,1,0,0,1,3,3H21a1,1,0,0,1,0,2Z"/><path class="cls-1" d="M21,17H3a3,3,0,0,0,0,6H21a3,3,0,0,0,0-6Zm0,4H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"/><path class="cls-1" d="M21,9H3a3,3,0,0,0,0,6H21a3,3,0,0,0,0-6Zm0,4H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"/></svg>                
                </div>

            </div>

             
                

            <div className="categoryAdminMain flex flexRow flexWrap flexJustifyStart ">
              
                   
                
                <div className="categoryAdminBody">
                    <AdminAdministrator  notifyParent={()=>{setSignal(!signal)}}/>
                </div>
            </div>
        </div>
    );
}