import React, { useEffect, useRef, useState } from "react";
import "./ImageCarouselStyle.css";
import HOST from "../../configuration/backend/host";
import PROXY from "../../configuration/proxy/proxy";



export default function ImageCarousel({imageIdList, resetSignal}){


    let [curentIndex, setCurentIndex] = useState(0);
     let carousel = useRef(null);
     const [startX, setStartX] = useState(null);
     const [endX, setEndX] = useState(null);


     useEffect(()=>{
        setCurentIndex(0);
     },[resetSignal]);

     useEffect(()=>{
 


        if(!carousel.current)
        {
            return;
        }
        let timeout = null;
            let resizeObserver = new ResizeObserver(()=>{
                carousel.current.style.transition = "0s";
                setCurentIndex((value)=>{
                    console.log("RESIZE");
                    
                    carousel.current.style.transform = "translateX("+(-1*value*carousel.current?.clientWidth)+"px)";

                    return value;
                });
                if(timeout)
                {clearTimeout(timeout)}
                timeout = setTimeout(()=>{console.log("timout");if(carousel.current){carousel.current.style.transition =  " 1.2s  cubic-bezier(0.5, 0.25, 0.15, 1)";} timeout = null;},500);

               
             });
            resizeObserver.observe(carousel.current);
        
        return ()=>{
            resizeObserver.disconnect();
        }
    },[]);
    const generateImageList = ()=>{

         let result = [];
        for (let index in imageIdList)
        {
            result.push(
                <div className="imageWrapper flex flexRow flexJustifyCenter flexAlignItemsCenter flexAlignContentCenter ">
                    <img ref = {(el)=>{if(el){el.onload = ()=>{console.log("imageloaded");}}}}  className="image" src={HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/image/"+imageIdList[index]+"?width=1000"}/>
                </div>
            );
        }
        return result;
    }
    const computeNextIndex=(crInd)=>{
        if(crInd<imageIdList.length-1)
        {
            setCurentIndex(crInd+1);
        }
    }
    const computePreviousIndex=(crInd)=>{
        if(crInd>0)
        {
            setCurentIndex(crInd-1);
        }
    }

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
      };
    
      const handleTouchMove = (e,ind) => {
        setEndX(e.touches[0].clientX);
        //carousel.current.style.transform = "translateX("+((-1*ind*carousel.current?.clientWidth)+e.touches[0].clientX-startX)+"px)";

      };
    
      const handleTouchEnd = (crInd) => {
        if (startX !== null && endX !== null) {
          const deltaX = endX - startX;
    
          if (deltaX > 0) {
            // Swipe right
            computePreviousIndex(crInd);
            console.log('Swipe right');
          } else if (deltaX < 0) {
            // Swipe left
            computeNextIndex(crInd);
            console.log('Swipe left');
          }
        }
    
        // Reset values
        setStartX(null);
        setEndX(null);
      };
    
    return(
        <div className="imageCarouselMain">
             {(imageIdList && (curentIndex>0)) && <div onClick = {()=>computePreviousIndex(curentIndex)} className="carouselControl previous"/>}
             {(imageIdList && (curentIndex<imageIdList.length-1)) && <div onClick = {()=>computeNextIndex(curentIndex)}className="carouselControl next"/>}
            <div  
            
            onTouchStart={handleTouchStart}
            onTouchMove={(e)=>handleTouchMove(e,curentIndex)}
            onTouchEnd={()=>handleTouchEnd(curentIndex)}
            ref={carousel} className="imageListContainer" style = {{transform:"translateX("+(-1*curentIndex*carousel.current?.clientWidth)+"px)"}}>
                {generateImageList()}
            </div>

        </div>
    );

}