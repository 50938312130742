import { render } from "@testing-library/react";
import React from "react";
import DeviceIntrospector from "../../device/introspector/deviceIntrospector";
import InputMangerDOM from "../../device/input/inputManagerDOM";
import "./pageEntryViewStyle.css"

 export default class PageEntryAnimatedView{

    constructor()
    {
        this.speed = 0.02;// 10 rem per sedcond
        this.pinHeight = 1;
        this.leftContainerProperties= {left:0};
        this.rightContainerProperties= {right:0};
        this.mainContainerProperties = {opacity:0.7};

        // or could be done with canvas
        this.mainContainer = document.createElement("div");
        this.mainContainer.classList.add("entryPageMain");

        this.leftContainer = document.createElement("div");
        this.leftContainer.classList.add("left");
 
        this.rightContainer = document.createElement("div");
        this.rightContainer.classList.add("right");
 
        
        this.zipperContainer = document.createElement("div");
        this.zipperContainer.classList.add("zipper");
  
        this.zX = 0;
        this.zY = 0;
        this.previousHeadY = -1;
        this.zipperHead = document.createElement("div");
        this.zipperHead.classList.add("zipperHead");
         InputMangerDOM.registerDragHandler(this.zipperHead,
                                            (x,y)=>{this.zX = x; this.zY=y; this.previousHeadY=y; console.log("drop",x,y);}, 
                                            (x,y)=>{ console.log("drag",x,y);
                                                    
                // calculate the new cursor position:
                let pos1 = this.zX - x;
                let pos2 = this.zY - y;
                this.previousHeadY = this.zY;

                this.zX = x;
                this.zY = y;
                // set the element's new position:
                this.zipperHead.style.top = (Math.min(Math.max(this.zipperHead.offsetTop - pos2, this.zipperContainer.offsetTop), this.zipperContainer.offsetTop+this.zipperContainer.offsetHeight-this.zipperHead.offsetHeight)) + "px";
                //this.zipperHead.style.left = (this.zipperHead.offsetLeft - pos1) + "px";
        
        });

        this.mainContainer.appendChild(this.leftContainer);
        this.mainContainer.appendChild(this.zipperContainer);
        this.mainContainer.appendChild(this.rightContainer);
        this.zipperContainer.appendChild(this.zipperHead);

        this.zipperPins = [];
        

        this.alive = true;
        this.visible = false;
        this.timeFromDeath = 300;
        this.trigered = false;
        this.lastZipperHeight = -1;
        this.lastHeadPosition = -1;
        this.retracting = false;
        this.aliveFor = 0;

        //this.computeZipper();

  
    }
// the only way to hold reference to my elements, withouth using id, tags, etc., is to create them the by js and hold the reference

    getViewContainer(){
        return this.mainContainer;
    }
    update(delta){
       if(!this.retracting){
        if((this.zY>0 || true) && this.zipperHead.offsetTop<=this.zipperContainer.offsetTop+10)
            {
                    //this.retracting = true;
                    this.trigered = true;
                    //this.alive = false;
                    this.zipperContainer.remove();
                    console.log("a");


             }

       
        let zH = this.zipperContainer.offsetHeight;
        if(zH!==this.lastZipperHeight)
        {
            this.computeZipper();
            this.lastZipperHeight = zH;
            this.renderZipper(true);
        }
        this.speed = this.mainContainer.offsetWidth*0.0008;
            if(this.trigered){
                if(!this.alive)
                {
                    this.timeFromDeath = this.timeFromDeath+delta;

                    this.mainContainerProperties.opacity  = Math.min((1000-this.timeFromDeath)/1000, 0.7);
                    if(this.timeFromDeath>1000)
                    {
                        this.destroy();
                    }
                }
                if(this.visible )
                {
                    this.leftContainerProperties.left = this.leftContainerProperties.left+this.computeDisplacement(delta,-1);
                    this.rightContainerProperties.right = this.rightContainerProperties.right+this.computeDisplacement(delta,-1);

                
                    if(this.leftContainer.offsetLeft+this.leftContainer.offsetWidth<0)
                    {
                        this.visible=false;
                        this.alive = false;
                    }
                }
                else{
                    //test visibility

                    if(this.leftContainer.offsetLeft+this.leftContainer.offsetWidth>0)
                    {
                        this.visible=true;
                    }
                }

            }
            this.animateZiperHead(delta);
        }
        else{
            
        }
         this.render();
        

    }
    computeDisplacement(delta, velocity){
        return  delta * this.speed * velocity;

    }
    
    render(){
        this.leftContainer.style = " left:"+this.leftContainerProperties.left+"px";
        this.rightContainer.style = " right:"+this.rightContainerProperties.right+"px";
        this.mainContainer.style = " backdrop-filter: blur("+this.mainContainerProperties.opacity*10+"px) ; -webkit-backdrop-filter: blur("+this.mainContainerProperties.opacity*10+"px)";
 
        this.renderZipper();

    }

    animateZiperHead(delta)
    {
        this.aliveFor = this.aliveFor+ delta;
        if(this.aliveFor>=5000)
        {
            this.zipperHead.style.top = this.zipperHead.offsetTop-Math.max(this.zipperHead.offsetTop-this.zipperContainer.offsetTop,50)*delta/1000+"px";
            this.renderZipper(true);
        }
    }
    renderZipper(force){
        //if (changes in the zipperHead position or if zipper not yet initialized)
        
        if(force || this.zY!==this.previousHeadY)
        {
          
            for(let index in this.zipperPins)
             {
                let pin = this.zipperPins[index];
                let dif =   (this.zipperHead.offsetTop+this.zipperHead.offsetHeight/2) - (pin.offsetTop+pin.offsetHeight/2);
                if(dif>0)
                {
                    let max = Math.min(dif,25);
                    (index%2===0)?pin.style.left= max+"px":pin.style.right = max+"px"

                }
                else{
                    (index%2===0)?pin.style.left= 0+"px":pin.style.right = 0+"px"

                }
                /*
                let pin = document.createElement("div");
                pin.classList.add("pin");
                let style = " ";
                style =  style + "height:"+this.pinHeight+"rem; ";
                style = style + "top:" + counter*this.pinHeight+"rem;  ";
                style = style +  ((counter%2==0)?"left:0;":"right:0; ");
                pin.style = style;
                this.zipperPins.push(pin);
                this.zipperContainer.appendChild(pin);
                */
                //Trif Dumitru
             }
            
            
            
            this.previousHeadY = this.zY;
        }

    }
    computeZipper(){
        //if (changes in the zipperHead position or if zipper not yet initialized)
        
            //regenerate everything everytime after do it efficient and add window rezise listener
            for(let index in this.zipperPins)
            {
                this.zipperPins[index].remove();
              }
            this.zipperPins =[];
            let numberOfPins = this.zipperContainer.offsetHeight / (this.pinHeight * parseFloat(getComputedStyle(document.documentElement).fontSize));
            let counter = 0;
            //console.log("pins", numberOfPins, this.zipperContainer.offsetHeight);
            while(counter<numberOfPins)
            {
                let pin = document.createElement("div");
                pin.classList.add("pin");
                let style = " ";
                style =  style + "height:"+this.pinHeight+"rem; ";
                style = style + "top:" + counter*this.pinHeight+"rem;  ";
                style = style +  ((counter%2==0)?"left:0;":"right:0; ");
                pin.style = style;
                this.zipperPins.push(pin);
                this.zipperContainer.appendChild(pin);
                
                //Trif Dumitru
                counter++;
            }
            
            
           

    }
    destroy(){
        if(this.mainContainer.parentNode)
        this.mainContainer.parentNode.removeChild(this.mainContainer);
        InputMangerDOM.removeDragHandler(this.zipperHead);
    }

     
}