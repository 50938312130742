const REDIRECTHOST=
{
 name: window.location.hostname,
 port:  ((window.location.port && (window.location.port.length>0))?(":"+window.location.port):""),
 schema: window.location.protocol,

};
const generateGoogleOauthLink = ()=>{
    return "https://accounts.google.com/o/oauth2/auth?client_id=382478703840-9gjapumopk81v22ak41t5sk5ungngakf.apps.googleusercontent.com&redirect_uri="+generateRedirectUri() +"&response_type=code&scope=email%20profile";

}
const generateRedirectUri = ()=>{
  return REDIRECTHOST.schema+"//"+REDIRECTHOST.name+REDIRECTHOST.port+"/auth/google/callback";

}

export {generateGoogleOauthLink, generateRedirectUri}
