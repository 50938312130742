import React, { useEffect, useState } from "react";
import "./../category/CategoryTreeStyle.css";
import {performHttpRequest} from "./../../api/http/generic";
import ProductTree from "./ProductTree";
import { redirect } from "react-router-dom";
export default function ProductNode({categoryId, selectionHandler, selectedCategoryList}){

    let [category, setCategory] = useState(null);
    let [explore, setExplore] = useState({open:false, build:false});
    let [selectState, setSelectState] = useState(0);
    useEffect(()=>{
        performHttpRequest("POST", "/category/filterWithStatistics?arguments=id,name",null, JSON.stringify({id:categoryId}), (r,c,e,j)=>{
            if(c>=200 && c<=206)
            {
                if(r)
                {
                    if(Array.isArray(r) && r.length>0)
                    {
                        setCategory(r[0]);
                    }
                    else{
                        setCategory(r);
                    }
                }
            }
        })
        {

        }
    },[categoryId]);
    useEffect(()=>{
        if(selectedCategoryList && selectedCategoryList.includes(categoryId))
        {
            setSelectState(2);
        }
    },[selectedCategoryList]);

    const internalSelectionHandler = ()=>{
        switch (selectState){
            case 0: {
            
                setSelectState(1); 
                selectionHandler([category?.id], false, false);
                break;
            }
            case 1:{
                setSelectState(2);
                selectionHandler([category?.id], true, false);
                break;
            }
            case 2:{
                setSelectState(0);
                selectionHandler([category?.id], false, true);
                break;

            }
       }

    }
    const passedSelectionhandler = (categoryList, propagate, reset)=>{
        if(reset)
        {
            setSelectState(0);
            selectionHandler([...categoryList, category?.id], false, true);
        }else{
            if(propagate)
            {
                setSelectState(2);
                selectionHandler([...categoryList, category?.id], true, false);
            }
            else{
                selectionHandler(categoryList,false, false);
            }
        }

    }
    const toogleExplore = ()=>{
      setExplore({
        open: !explore.open,
        build:true
      });
    }
    return (
        <div className="categoryNodeMain">
            <div className={("categoryNodeTitle flex flexRow flexNoWrap flexJustifySpaceBetween")+((selectState===2 || selectState ===1)?" selected":"")}>
                <div  onClick={()=>internalSelectionHandler()} className="categoryNodeName boldText">{category?.name}</div>

                {category&&category.numberOfChildren>0 && <div onClick={()=>toogleExplore()} className="categoryNodeExplore"> 
                {(explore.open)?(<svg  className="categoryNodeExpandIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                 <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                </svg>):(<svg  className="categoryNodeExpandIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.2rem" stroke-linecap="round" stroke-linejoin="round">
                <line x1="50%" y1="5%" x2="50%" y2="95%"></line>
                <line x1="5%" y1="50%" x2="95%" y2="50%"></line>
                </svg>)}
                </div>}
            </div>
            <div className={"categoryNodeChildren " + ((explore.open)?"open":"close")}>
                {category && category.id && explore.build && <ProductTree selectedCategoryList={selectedCategoryList} selectionHandler = {passedSelectionhandler} parentId={category?.id}/>}
            </div>
        </div>
    );

}