import React, { useEffect, useState } from "react";
import "./ColumnComponentContainerStyle.css";
import { clearAllByPrefix, loadText, storeText } from "../api/ObjectStoreApi";
import ComponentsMetaDataset from "./componentDataset/componentsMeta";
import ComponentSelector from "./componentSelector/ComponentSelector";
import Modal from "../modal/Modal";


export default function ColumnComponentContainer ({admin, tagPrefix}){

    let [componentsMeta, setComponentsMeta] = useState({components:[], bookingVector:[]});
    let [selectionOpen, setSelectionOpen] = useState(false);
    useEffect(()=>{
        setComponentsMeta({components:[], bookingVector:[]});
        fetchComponentsMeta();
    },[admin,tagPrefix]);

    const fetchComponentsMeta = ()=>{
        loadText(tagPrefix+".componentsMeta").then(r=>{
            try{
                let rObject = JSON.parse(r);
                if(Array.isArray(rObject.components))
                {
                    setComponentsMeta(rObject);
                }
            }
            catch(e){}
        }).catch(e=>{});
    }
    const saveComponentsMeta = (data)=>{
        storeText(tagPrefix+".componentsMeta", JSON.stringify(data)).then(r=>fetchComponentsMeta(), e=>fetchComponentsMeta());
    }

    const housekeepFindAndBookId = (bookingVector, nrOfComponents ) =>{
        if(Array.isArray(bookingVector))
        {
            if(bookingVector.length < nrOfComponents)
            {
                let bookingSpacestoAdd = nrOfComponents-bookingVector.length;
                for(let i=0; i<bookingSpacestoAdd;i++)
                {
                    bookingVector.push(false);
                }
            }
            let index  = 0;
            while(index<bookingVector.length &&bookingVector[index])
            {
                index++;
            }
            bookingVector[index] = true;
            return index+1;

        }

    }
    const addNewElement =(position , elementKey)=>{

        // must be called from inside a lambda
    
        let cmpsMeta = JSON.parse(JSON.stringify(componentsMeta));
        if(cmpsMeta && Array.isArray(cmpsMeta.components) && Array.isArray(cmpsMeta.bookingVector))
        {
            let cmpId = housekeepFindAndBookId(cmpsMeta.bookingVector , cmpsMeta.components.length+1);
            if(position>=cmpsMeta.components.length){
                cmpsMeta.components.push({id:cmpId, datasetKey:elementKey});
            }
            else{
                cmpsMeta.components.splice(position, 0,{id:cmpId, datasetKey:elementKey});
            }
            saveComponentsMeta(cmpsMeta);
        }
    }
    const removeElement = (position)=>{
        let cmpsMeta = JSON.parse(JSON.stringify(componentsMeta));
        if(cmpsMeta && Array.isArray(cmpsMeta.components) && Array.isArray(cmpsMeta.bookingVector) && position>=0&&position< cmpsMeta.components.length)
        {
            let cmp = cmpsMeta.components[position];
            let bookIndex = cmp.id -1;
            cmpsMeta.bookingVector[bookIndex] = false;
            if(bookIndex === cmpsMeta.bookingVector.length-1)
            {
                cmpsMeta.bookingVector.splice(bookIndex,1);
            }
            cmpsMeta.components.splice(position,1);
            clearAllByPrefix(tagPrefix+"."+cmp.id).then(r=>{}, e=>{});
            saveComponentsMeta(cmpsMeta);
        }
        
    }
    const generateContent = ()=>{

        let result = [];
        if(admin)
        {
            result.push(
                <div className="fullParentWidth flex flexRow flexJustifyCenter" style={{padding:"0.5rem", borderRadius:"0rem", boxShadow:"0 0 0.5rem 0.01rem black inset"}}>
                    <div  onClick={()=>{setSelectionOpen(1)}} className="add"></div>
                </div>);
            
            if(componentsMeta && Array.isArray(componentsMeta.components))
            {
                for(let index in componentsMeta.components)
                {
                    let cMeta = componentsMeta.components[index];
                    if(cMeta.datasetKey && cMeta.id){
                        let datasetEntry = ComponentsMetaDataset[cMeta.datasetKey];
                        if(datasetEntry)
                        {
                            let reactComponent = datasetEntry.getComponent({admin:admin, tagPrefix: tagPrefix+"."+cMeta.id});
                            result.push(
                                <div  className="fullParentWidth flex flexRow flexWrap flexJustifySpaceBetween" style={{padding:"0.5rem"}}>
                                    <div className="breakWordPretty" style={{fontSize:"1.5rem", borderBottom:"1px solid black", padding:"0", textAlign:"center", width:"fit-content", height:"fit-content"}}> {datasetEntry.title} </div>
                                    <div onClick = {()=>{removeElement(new Number(index))}}  className="close"></div>
                                </div>

                                );
                            
                            result.push(reactComponent);
                            if(admin)
                            {
                                
                                result.push(
                                    <div  className="fullParentWidth flex flexRow flexJustifyCenter"style={{padding:"0.5rem", borderRadius:"0rem", boxShadow:"0 0 0.5rem 0.01rem black inset"}}>
                                        <div  onClick = {()=>setSelectionOpen(new Number(index)+2)} className="add"></div>    
                                    </div>

                                );

                            }
                        }
                    }
                    
                }
            }
        }else{
             
               
              
                
                if(componentsMeta && Array.isArray(componentsMeta.components))
                {
                    for(let index in componentsMeta.components)
                    {
                        let cMeta = componentsMeta.components[index];
                        if(cMeta.datasetKey && cMeta.id){
                            let datasetEntry = ComponentsMetaDataset[cMeta.datasetKey];
                            if(datasetEntry)
                            {
                                let reactComponent = datasetEntry.getComponent({admin:admin, tagPrefix: tagPrefix+"."+cMeta.id});
                               
                                result.push(reactComponent);
                                 
                            }
                        }
                        
                    }
                }
            
        }
        return result;
    }
    return(
        <div className = "columnComponentContainerMain fullParentWidth">
            {generateContent()}
            <Modal element={<ComponentSelector selectionHandler={(key)=>{addNewElement(selectionOpen-1, key);setSelectionOpen(false);}}/>}
                    isOpen={selectionOpen}
                    closeFunction={()=>setSelectionOpen(false)}/>
         </div>
    );
}
