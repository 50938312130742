 

async function downloadFile(fetchResult) {        
    var filename = fetchResult.headers.get('content-disposition').split('filename=')[1];
    if(filename.includes('"'))
    {
    filename = filename.split('"')[1];
    }
    console.log("FILENAME", filename);
    var data = await fetchResult.blob();
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([data], { type: data.type || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
        return;
    }
    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}


function performRequest(request, callback){


    let token = window.localStorage.getItem("token");
    if( token != null)
    {
    request.headers.append("Authorization", "Bearer "+token);
 }
  
  
     fetch(request)
     .then(
        function(response) {
            if (response.ok) {
              
                /*
    httpReporterBroker.publishEvent({
        type:"success",
        method:request.method,
        url:request.url,
        status: response.status
    });*/
                 const contentType = response.headers.get("content-type");
                 const contentDisposition = response.headers.get("content-disposition");



               // console.log(contentDisposition);


               if(response && contentDisposition && contentDisposition.includes("attachment"))
               {
                   console.log("containse");
                   downloadFile(response);

               }
               else{
                if(response!==null&& contentType && contentType.includes("application/json"))
                {                    
                     
                    response.json().then(json => callback(json, response.status,null, true));
                }
                else{
                    if(response!==null&& contentType && contentType.includes("pdf"))
                {                    
                     
                  
                }
                
            
                

                response.text().then(json => callback(json, response.status,null, false));
                
                
                }
            }

            }
            else {
                //httpReporterBroker.publishEvent({"type":"error","method":request.method, "url":request.url, "status": response.status});
                console.log("REQ", request, response);
                const contentType = response.headers.get("content-type");

                if(response!==null&& contentType && contentType.includes("application/json"))
                {
                     response.json().then(err => callback(null, response.status,  err, true));
                }
                else{
                    response.text().then(err => callback(null, response.status,  err, false));

                }
            }
        })
    .catch(function (err) {
         callback(null, 1, err)
    });
}


 export {
    performRequest
 }
