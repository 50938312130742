import React, { useEffect, useState } from "react";
import "./ProductExploreStyle.css"
import CategoryTree from "./categoryTree/CategoryTree";
import ProductDashboard from "./ProductDasboard";
import { useLocation, useNavigate } from "react-router-dom";
import { performHttpRequest } from "../../api/http/generic";


export default function ProductExplore({hidden}){

    let [category, setCategory] = useState(false);
    let [sidebarActivated, setSidebarActivated] = useState(false);

    let location = useLocation();
    const redirect = useNavigate();
     useEffect(()=>{
        let queryParams = new URLSearchParams(location.search);
        let catId = queryParams.get("cId");
        console.log("params:", queryParams);
        if(catId)
        {
            try{
                let catIdNumber = new Number(catId);
                performHttpRequest("GET","/generic/category/"+catIdNumber+"?attributes=id,name", null, null, (r,c,e,j)=>{
                    if(c>=200 && c<=206)
                    {
                        if(r.id)
                        {
                            setCategory({id:r.id, name:r.name});
                        }
                    }
                } );
                 return;
            }catch(e){}
        }
        setCategory({id:null});
    },[location]);
    return(
        <div className={"productExploreMain  "+(hidden?"hidden":"")}>
            <div className="sidebar  flex flexColumn">
                <div className="title"  onClick={()=>{redirect("/product")}}>Produse:</div>
                <CategoryTree selectionHandler={(catId, catName)=>{redirect("/product?cId="+catId)}}/>
            </div>
            <div className="dropdownActivationWrapper flexRow flexJustifySpaceBetween flexAlignContentCenter flexAlignItemsCenter">
                <div></div>
                <div onClick = { ()=>setSidebarActivated(!sidebarActivated)}className="dropdownActivation flex flexRow flexNoWrap flexJustifyCenter flexAlignItemsCenter flexAlignContentCenter">
                    <div>Categorii</div>
                    <div className={"icon "+(sidebarActivated?"down":"right")}/>
                </div>
                
                
                {sidebarActivated && <div className="dropdown">
                    <CategoryTree selectionHandler={(catId, catName)=>{setSidebarActivated(false);redirect("/product?cId="+catId); }}/>

                </div>}
            </div>
            <div className = "sidebarActivator">

            </div>
            <div className="dashboard">
                
               {category && <ProductDashboard categoryId={category?.id} categoryName={category?.name}/>}
            </div>
        </div>);
}