import React from "react";

import "./ProductExploreStyle.css";
import ProductPage from "./ProductPage";
import ProductExplore from "./ProductExplore";
import { Route, Routes } from "react-router-dom";
import ProductTopbar from "./ProductTopbar";
import ColumnComponentContainer_1Hardcoded from "../../genericComponents/componentContainer/ColumnComponentContainer_1Hardcoded";

export default function ProductRouter(){

    return(
        <div className=" productRouterMain fullParentWidth flex flexColumn flexJustifyStart">
         

            
            <Routes>
                         
                        <Route path = {"/"} element = {
                            <div>
                                <ColumnComponentContainer_1Hardcoded  hidden = {false} tagPrefix={"productDashboard1"} harcodedElement={<ProductExplore/>} />
                                <ColumnComponentContainer_1Hardcoded  hidden = {true} tagPrefix={"productPage1"} harcodedElement={<ProductPage/>} />

                            </div>
                        }></Route>
                        <Route path = {"/:productId"} element = {
                            <div>
                               <ColumnComponentContainer_1Hardcoded  hidden = {true} tagPrefix={"productDashboard1"} harcodedElement={<ProductExplore/>} />
                                <ColumnComponentContainer_1Hardcoded  hidden = {false} tagPrefix={"productPage1"} harcodedElement={<ProductPage/>} />

                            </div>
                        }></Route>
                        
                    </Routes>
        </div>
    );
}