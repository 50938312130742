import React, { useState } from "react";
import "./../category/AddCategoryFormStyle.css";
import { performHttpRequest } from "../../api/http/generic";
import useHistory, { useNavigate } from "react-router-dom";
export default function DeleteProductForm({title, category, closeFunction})
{


    let [name, setName] = useState("");
    let navigate = useNavigate()
    const addNewCategory = ()=>{
        performHttpRequest("DELETE", "/generic/product/"+category?.id, null,null,(r,c,e,j)=>{
                if(c>=200 && c<=206)
                {
                    //alert("SUCCESS");
                    navigate(-1);
                    closeFunction();
                }
        });
    }
    return (
        <div className="addCategoryFormMain flex flexColumn flexJustifySpaceBetween flexAlignContentCenter flexAlignItemsCenter">
            <div className="addCategoryFromHeader boldText ">{title?title:""}</div>
            <div className="addCategoryFormBody flex flexColumn flexJustifyStart">

                <div className="">Sterge definitiv produsul {category?.title}</div>
                 
                 

            </div>
            <div className="addCategoryFormFooter flex flexRow flexWrap flexJustifySpaceEvenly">
                <button onClick={()=>addNewCategory()}>Sterge definitiv</button>
                <button onClick={()=>closeFunction()}>Renunta</button>

            </div>
        </div>
    );
}