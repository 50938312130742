import React, { useEffect, useState } from "react";
import "./P1T1S1Style.css";
import { clearImage, clearText, loadText, storeImage, storeText } from "../../api/ObjectStoreApi";
import HOST from "../../../configuration/backend/host";
import PROXY from "../../../configuration/proxy/proxy";
import FileSelector from "../../fileSelector/FileSelector";
import "../GenericStyle.css";

export default function P1T1S1({tagPrefix, admin, itemDeletionHandler, imgWidth}){


    let [textData, setTextData] = useState({title:"Title", subtitle:"subtitle",link:"link"})
    let [imageFile, setImageFile] = useState(null);
    let [updateSignal, setUpdateSignal] = useState(1);

     
    useEffect(()=>{
        setTextData({title:"Title", subtitle:"subtitle",link:"link"});
        fetchTextData()},[tagPrefix, admin, updateSignal]);
    const handleImageChange = (e)=>{
         setImageFile(e.target.files[0]);
    }
    const handleFileChange = (files)=>{
        
        setImageFile(files[0]);
    }
    const uploadTextData = () =>{
        if(textData)
        {
            storeText(tagPrefix+".textData", JSON.stringify(textData)).then((r)=>{setUpdateSignal(updateSignal+1)})
        }
    }
    const fetchTextData = () =>{
         console.log("will fetch text");
        console.log();
            loadText(tagPrefix+".textData").then((rawR)=>{
                console.log("RASPUNS", rawR);
                try{
                    let r = JSON.parse(rawR);
                if(r && r.title && r.subtitle && r.link)
                {
                     setTextData(r);
                
                }}catch(e){}

            }).catch(e=>{ setTextData({title:"Title", subtitle:"subtitle",link:"link"})});
       
    }
    const uploadImageFile = () =>{
        if(imageFile)
        {
            storeImage(tagPrefix+".image", imageFile).then((r)=>{setUpdateSignal(updateSignal+1)})
        }
    }
    const uploadInputImageFile = (file) =>{
        if(file)
        {
            storeImage(tagPrefix+".image", file).then((r)=>{setUpdateSignal(updateSignal+1)})
        }
    }
    const cleanDatastore = ()=>{
        
        //clearText(tagPrefix+".textData").then(r=>setUpdateSignal(u=>u+1)).catch(e=>{}).then(()=>{
        //clearImage(tagPrefix+".image").then(r=>setUpdateSignal(u=>u+1)).catch(e=>{}).then(()=>{itemDeletionHandler()})});
        itemDeletionHandler();
    }
    return(
        <div className="p1t1s1Main"  style = {{border:(admin?"1.5px solid black":"none"), borderRadius:(admin?"1rem":"")}}>
            {admin && 
                <div className="admin">
                    <div className="imageLoad">
                    <div className="flex flexColumn flexJustifyStart flexAlignItemsCenter flexAlignContentCenter" style={{width:"100%", height:"fit-content",
                        bS:"contain",
                        bR:"no-repeat",
                        bP:"center",
                        bI:"url('"+HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/objectstore/image/"+tagPrefix+".image?imgKey="+updateSignal+"&width="+("250")+"')"}}>
                            <img src = {HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/objectstore/image/"+tagPrefix+".image?imgKey="+updateSignal+"&width="+("250")}></img>
                        </div>
                        
                       <FileSelector uploadFiles={(files)=>{
                            console.log("files", files);
                            if(files && Array.isArray(files) && files.length>0){
                                handleFileChange(files);
                                uploadInputImageFile(files[0]);
                                }
                            }}/>
                        {/*<input type="file" id="image" accept="image/*" onChange={handleImageChange} style={{maxWidth:"100%", wordWrap:"break-word"}}/>*/}
                        {/*<div className="check" onClick={()=>uploadImageFile()}></div>*/}
                    </div>
                    <div className="titleEdit">
                        <div className="contentEditableDiv" contentEditable onInput={(event)=>{}} onBlur={(event)=>{setTextData({subtitle:textData?.subtitle, title:event.target.innerText, link:textData?.link});}}>
                            {textData?.title}
                        </div>
                    </div>
                    <div className="subtitleEdit">
                        <div className="contentEditableDiv" contentEditable onInput={(event)=>{}} onBlur={(event)=>{setTextData({subtitle:event.target.innerText, title:textData?.title, link: textData?.link});}}>
                            {textData?.subtitle}
                        </div>
                    </div>
                    <div className="linkEdit">
                        <div className="contentEditableDiv" contentEditable onInput={(event)=>{}} onBlur={(event)=>{setTextData({subtitle:textData?.subtitle, title: textData?.title, link:event.target.innerText});}}>
                            {textData?.link}
                        </div>
                    </div>
                    
                    <div onClick = {()=>uploadTextData()} className="flex flexRow flexWrap flexJustifyCenter flexAlingContentCenter flexAlignItemsCenter">
                        <div>Salveaza</div>
                        <div className="check" ></div>
                    </div>
                   {/*<div className="check" onClick = {()=>cleanDatastore()}></div>*/}

                </div>
            }
            
            {!admin && 
                <div className="view" style={{cursor:"pointer"}} 
                onClick={()=>{if(textData.link && textData.link.length>1){window.location.href = textData.link}}}
                >
                    <div>
                        <div className="title breakWordPretty">{textData.title}</div>
                        <div className="subtitle breakWordPretty">{textData.subtitle}</div>
                    </div>
                    <div className=" image coverBackground" 
                    style = {{backgroundImage:"url('"+HOST.schema+"//"+HOST.name+HOST.port+PROXY.backend+"/objectstore/image/"+tagPrefix+".image?imgKey="+updateSignal+"&width="+(imgWidth?imgWidth:"600")+"')"}}></div>
                   

                </div>
            }

        </div>
    );
}